<template>
  <div class="stat">
    <div v-if="isLoading" class="overlay">
      <!-- Add a loading indicator component or message here -->
      <div class="loading-text loader">

      </div>
    </div>
    <div>
          <v-row>
            <v-col cols="12" md="4">
              <v-select
                  v-model="selectedResellers"
                  :items="resellers"
                  item-text="name"
                  item-value="id"
                  :label="$t('statistics.resellers')"
                  multiple
                  append-icon="mdi mdi-chevron-down"
                  prepend-icon="mdi mdi-account-tie"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">
              <v-select
                  v-model="selectedItems"
                  :items="contracts"
                  item-text="name"
                  item-value="id"
                  :label="$t('statistics.contracts')"
                  multiple
                  append-icon="mdi mdi-chevron-down"
                  prepend-icon="mdi mdi-file-document-edit-outline"
              ></v-select>
            </v-col>
            <v-col cols="12" md="3">

              <v-text-field
                  v-model="dateShow"
                  :label="$t('statistics.select_date_range')"
                  readonly
                  @click="openDialog"
                  prepend-icon="mdi mdi-calendar"
              ></v-text-field>


            </v-col>
            <v-col cols="12" md="1">
                <v-btn
                    class="mt-3"
                    outlined
                    @click="submitFilters"
                >
                    <v-icon>mdi mdi-filter-outline</v-icon>
                    {{$t('statistics.filter')}}
                </v-btn>
            </v-col>
          </v-row>
          <v-row>

            <v-col cols="12" sm="6" md="4">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                <div v-on="on">

                  <v-stat
                      :value="totalTenants"
                      backgroundColor="black"
                      icon="mdi mdi-account-group"
                      :aria="ariaTenants"
                      :allegra="allegraTenants"
                      :label="$t('statistics.total_tenants')"
                  />
                </div>
                </template>
                <span>{{$t('statistics.total_tenants_details')}}</span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on">
                     <v-stat
                         :value="totalActiveTenants"
                         backgroundColor="green"
                         :aria="ariaActiveTenants"
                         icon="mdi mdi-account-multiple-check"
                         :allegra="allegraActiveTenants"
                         :label="$t('statistics.active_tenants')"
                     />
                  </div>
                </template>
                <span>{{$t('statistics.active_tenants_details')}}</span>
              </v-tooltip>
            </v-col>

            <v-col cols="12" sm="6" md="4">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on">
              <v-stat
                  :value="deactivatedTenants"
                  backgroundColor="red"
                  :aria="ariaDeactivatedTenants"
                  icon="mdi mdi-account-multiple-minus"
                  :allegra="allegraDeactivatedTenants"
                  :label="$t('statistics.deactivated_tenants')"
              />
                  </div>
                </template>
                <span>{{$t('statistics.deactivated_tenants_details')}}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on">
              <v-stat
                  :value="pendingActivationTenants"
                  :aria="ariaPendingActivationTenants"
                  backgroundColor="black"
                  icon="mdi mdi-account-clock"
                  :allegra="allegraPendingActivationTenants"
                  :label="$t('statistics.pending_activation_tenants')"
              />
                  </div>
                </template>
                <span>{{$t('statistics.pending_activation_tenants_details')}}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-on="on">
              <v-stat
                  :value="incompleteRegistrationTenants"
                  :aria="ariaIncompleteRegistrationTenants"
                  icon="mdi mdi-account-question"
                  backgroundColor="black"
                  :allegra="allegraIncompleteRegistrationTenants"
                  :label="$t('statistics.incomplete_registration_tenants')"
              />
                  </div>
                </template>
                <span>{{$t('statistics.incomplete_registration_tenants_details')}}</span>
              </v-tooltip>
            </v-col>
          </v-row>


          <v-row>
            <v-col cols="6">
              <Chart
                  :key="chart_cr_value.key"
                  :currency="currency"
                  :chart-name="$t('statistics.cash_register_cash_deposited')"
                  :props-labels="chart_cr_value.labels"
                  :orders-aria="chart_cr_value.aria"
                  :orders-allegra="chart_cr_value.allegra"
                  :orders-total="chart_cr_value.total"
                  :header-text="$t('statistics.cash_register_cash_deposited')"/>
            </v-col>
            <v-col cols="6">
              <Chart
                  :key="chart_cr_count.key"
                  :chart-name="$t('statistics.cash_register_count')"
                  :props-labels="chart_cr_count.labels"
                  :orders-aria="chart_cr_count.aria"
                  :orders-allegra="chart_cr_count.allegra"
                  :orders-total="chart_cr_count.total"
                  :header-text="$t('statistics.cash_register_count')"/>
            </v-col>
          </v-row>
          <v-row>

            <v-col cols="6">
              <Chart
                  :key="chart_cr_activated.key"
                  :chart-name="$t('statistics.cash_register_activated')"
                  :props-labels="chart_cr_activated.labels"
                  :orders-aria="chart_cr_activated.aria"
                  :orders-allegra="chart_cr_activated.allegra"
                  :orders-total="chart_cr_activated.total"
                  :header-text="$t('statistics.cash_register_activated')"/>
            </v-col>
            <v-col cols="6">
              <Chart
                  :key="chart_cr_deactivated.key"
                  :chart-name="$t('statistics.cash_register_deactivated')"
                  :props-labels="chart_cr_deactivated.labels"
                  :orders-aria="chart_cr_deactivated.aria"
                  :orders-allegra="chart_cr_deactivated.allegra"
                  :orders-total="chart_cr_deactivated.total"
                  :header-text="$t('statistics.cash_register_deactivated')"/>
            </v-col>
          </v-row>

          <v-row v-if="$store.state.user.role === 'admin'">
            <v-col cols="6">
                <Chart
                    :key="chart_cr_contract_activated_not_matched.key"
                    :chart-name="$t('statistics.cash_register_activated_not_matched')"
                    :props-labels="chart_cr_contract_activated_not_matched.labels"
                    :orders-aria="chart_cr_contract_activated_not_matched.aria"
                    :orders-allegra="chart_cr_contract_activated_not_matched.allegra"
                    :orders-total="chart_cr_contract_activated_not_matched.total"
                    :header-text="$t('statistics.cash_register_activated_not_matched')"/>
            </v-col>
            <v-col cols="6">
                <Chart
                    :key="chart_cr_contract_deactivated_not_matched.key"
                    :chart-name="$t('statistics.cash_register_deactivated_not_matched')"
                    :props-labels="chart_cr_contract_deactivated_not_matched.labels"
                    :orders-aria="chart_cr_contract_deactivated_not_matched.aria"
                    :orders-allegra="chart_cr_contract_deactivated_not_matched.allegra"
                    :orders-total="chart_cr_contract_deactivated_not_matched.total"
                    :header-text="$t('statistics.cash_register_deactivated_not_matched')"/>
            </v-col>
        </v-row>
    </div>

    <v-dialog v-model="show.dialog" width="350">
      <div>
        <v-card>
          <div class="dialog-header">
            {{$t('companies.payments.dialog')}}
          </div>
          <div style="padding:4%">
            <div style="text-align:center">
              <v-date-picker
                  v-model="date"
                  label="Date"
                  :max="currentDate"
                  range
                  @input="validateDateRange"
              >
              </v-date-picker>
            </div>

          </div>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
<script>

import VStat from "@/views/admin/Stats/VStat.vue";
import Chart from "@/views/shared/Chart.vue";

export default {
  components: {
    VStat,
    Chart
  },

  data() {
    return {
      calculationLabels: [
        this.$t('statistics.total_invoiced'),
        this.$t('statistics.total_paid'),
        this.$t('statistics.total_debt')
      ],
      show: {
        dialog: false,
      },
      dateShow: null,
      selectedResellers: [],
      resellers: [],
      ariaDeactivatedTenants: 0,
      allegraDeactivatedTenants: 0,
      ariaPendingActivationTenants: 0,
      allegraPendingActivationTenants: 0,
      ariaIncompleteRegistrationTenants: 0,
      allegraIncompleteRegistrationTenants: 0,
      ariaInvoices: 0,
      allegraInvoices: 0,
      totalTenants: 0,
      ariaTenants: 0,
      allegraTenants: 0,
      totalInvoiced: 0,
      totalPaid: 0,
      totalDebt: 0,
      totalActiveTenants: 0,
      ariaActiveTenants: 0,
      allegraActiveTenants: 0,
      deactivatedTenants: 0,
      pendingActivationTenants: 0,
      incompleteRegistrationTenants: 0,
      chart_cr_value: {
        aria: [],
        allegra: [],
        total: [],
        labels: [],
        key_label: 'chart_cr_value',
        key: ''
      },
      chart_cr_count: {
        aria: [],
        allegra: [],
        total: [],
        labels: [],
        key_label: 'chart_cr_count',
        key: ''
      },
      chart_cr_activated: {
        aria: [],
        allegra: [],
        total: [],
        labels: [],
        key_label: 'chart_cr_activated',
        key: ''

      },
      chart_cr_deactivated: {
        aria: [],
        allegra: [],
        total: [],
        labels: [],
        key_label: 'chart_cr_deactivated',
        key: ''
      },
      chart_cr_contract_value: {
        aria: [],
        allegra: [],
        total: [],
        labels: [],
        key_label: 'chart_cr_contract_value',
        key: ''
      },
      chart_cr_contract_count: {
        aria: [],
        allegra: [],
        total: [],
        labels: [],
        key_label: 'chart_cr_contract_count',
        key: ''
      },
      chart_cr_contract_activated: {
        aria: [],
        allegra: [],
        total: [],
        labels: [],
        key_label: 'chart_cr_contract_activated',
        key: ''
      },
      chart_cr_contract_activated_not_matched: {
        aria: [],
        allegra: [],
        total: [],
        labels: [],
        key_label: 'chart_cr_contract_activated_not_matched',
        key: ''
      },
      chart_cr_contract_deactivated: {
        aria: [],
        allegra: [],
        total: [],
        labels: [],
        key_label: 'chart_cr_contract_deactivated',
        key: ''
      },
      chart_cr_contract_deactivated_not_matched: {
        aria: [],
        allegra: [],
        total: [],
        labels: [],
        key_label: 'chart_cr_contract_deactivated_not_matched',
        key: ''
      },
      chart_billing: {
        aria: [],
        allegra: [],
        total: [],
        labels: [],
        key_label: 'chart_billing',
        key: ''
      },
      date: null,
      minDate:new Date().toISOString().substr(0, 10),
      contracts: [],
      selectedItems: [],
      isLoading: true,
      apiCallCount: 0,
      currentDate: null,
      increment: 0,
      currency: '',
    }
  },
  mounted() {
    this.currentDate = new Date().toISOString().substr(0, 10);
    this.date = [new Date(2021, 0, 1).toISOString().split('T')[0], this.currentDate];

    this.getCountry();
    this.getContracts();
    this.getResellers();

    this.submitFilters();
  },

  watch: {
    'date': {
        handler(newValue) {

          this.dateShow = this.getFormattedDateRange(newValue);
        },
        immediate: true, // Optional: Trigger the handler immediately on component creation
    },
  },
  methods: {
    getCountry(){
      ApiService.get('/admin/global-config')
          .then(response => {
            this.currency = response.data.country === 'me' ? '€' : 'RSD';
          })
          .catch(error => {
            console.log(error);
          });
    },

    validateDateRange() {
      const [startDate, endDate] = this.date;

      if (startDate && endDate && startDate >= endDate) {
        // If the first picked date is greater than or equal to the second one,
        // reset the date range or perform any desired action
        this.date = [endDate, startDate];
      }
    },
    submitFilters(){
      this.getTenantsData();
      this.getCashRegisterCountData();
      this.getCashRegisterValueData();
      this.getCashRegisterActivatedData();
      this.getCashRegisterDeactivatedData();

      //Run only if user is admin
      if(this.$store.state.user.role === 'admin')  {
          this.getCashRegisterDeactivatedDataWhereDatesDontMatch();
          this.getCashRegisterActivatedDataWhereDatesDontMatch();
      }
    },
    openDialog(){
      this.show.dialog = true;
    },

    getFormattedDateRange(dates) {
      if (Array.isArray(dates) && dates.length === 2) {
        const startDate = this.formatDate(dates[0]);
        const endDate = this.formatDate(dates[1]);
        return `${startDate} - ${endDate}`;
      }
      return '';
    },
    formatDate(date) {
      const parsedDate = new Date(date);
      const year = parsedDate.getFullYear();
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
      const day = String(parsedDate.getDate()).padStart(2, '0');

      // Use string interpolation to format the date as Y-m-d
      return `${day}/${month}/${year}`;
    },
    getContracts() {
      ApiService.get('/admin/contracts')
          .then(response => {
            this.contracts = response.data
          })
          .catch(error => {
            console.log(error);
          })
    },
    getTenantsData() {

      this.apiCallCount++; // Increment the counter for each API call

      this.isLoading = true; // Show the loader


      ApiService.get('/admin/tenants/stats',{
          params: {
              date: this.date,
              contractIds: this.selectedItems,
              resellerIds: this.selectedResellers
          }
      })
          .then(response => {
            this.incompleteRegistrationTenants = response.data.incomplete_registration_tenants;
            this.totalTenants = response.data.total_tenants;
            this.ariaTenants = response.data.aria_tenants;
            this.allegraTenants = response.data.allegra_tenants;
            this.totalActiveTenants = response.data.active_tenants;
            this.ariaActiveTenants = response.data.aria_active_tenants;
            this.allegraActiveTenants = response.data.allegra_active_tenants;
            this.deactivatedTenants = response.data.deactivated_tenants;
            this.pendingActivationTenants = response.data.pending_activation_tenants;
            this.ariaDeactivatedTenants = response.data.aria_deactivated_tenants;
            this.allegraDeactivatedTenants = response.data.allegra_deactivated_tenants;
            this.ariaPendingActivationTenants = response.data.aria_pending_activation_tenants;
            this.allegraPendingActivationTenants = response.data.allegra_pending_activation_tenants;
            this.ariaIncompleteRegistrationTenants = response.data.aria_incomplete_registration_tenants;
            this.allegraIncompleteRegistrationTenants = response.data.allegra_incomplete_registration_tenants;
                this.apiCallCount--; // Decrement the counter

                if (this.apiCallCount === 0) {
                  this.isLoading = false; // Hide the loader when all API calls are complete
                }
          })
          .catch(error => {
            console.log(error);
            this.$snackbar(this.$t('efi_transactions.error_string'), 'error');
            this.apiCallCount--; // Decrement the counter

            if (this.apiCallCount === 0) {
              this.isLoading = false; // Hide the loader when all API calls are complete
            }
          })
    },
    getCashRegisterValueData() {
      this.apiCallCount++; // Increment the counter for each API call

      this.isLoading = true; // Show the loader
      ApiService.get('/admin/cash-registers/value/stats', {
          params: {
              date: this.date,
              contractIds: this.selectedItems,
              resellerIds: this.selectedResellers
          }
      })
          .then(response => {

            this.chart_cr_value.aria = response.data.aria_paid;
            this.chart_cr_value.labels = Object.keys(this.chart_cr_value.aria);
            this.chart_cr_value.aria = Object.values(this.chart_cr_value.aria);
            this.chart_cr_value.allegra = Object.values(response.data.allegra_paid);
            this.chart_cr_value.total = this.chart_cr_value.aria.map((value, index) => value + this.chart_cr_value.allegra[index])
            this.chart_cr_value.key= this.chart_cr_value.key_label+'_'+this.increment;
            this.increment++;
            this.apiCallCount--; // Decrement the counter

            if (this.apiCallCount === 0) {
              this.isLoading = false; // Hide the loader when all API calls are complete
            }

          })
          .catch(error => {
            console.log(error);
            this.$snackbar(this.$t('efi_transactions.error_string'), 'error');
            this.apiCallCount--; // Decrement the counter

            if (this.apiCallCount === 0) {
              this.isLoading = false; // Hide the loader when all API calls are complete
            }
          })
    },
    getCashRegisterCountData() {
      this.apiCallCount++; // Increment the counter for each API call

      this.isLoading = true; // Show the loader
      ApiService.get('/admin/cash-registers/count/stats', {
          params: {
              date: this.date,
              contractIds: this.selectedItems,
              resellerIds: this.selectedResellers
          }
      })
          .then(response => {

            this.chart_cr_count.labels = Object.keys(response.data.aria_paid_cash_registers);
            this.chart_cr_count.aria = Object.values(response.data.aria_paid_cash_registers);
            this.chart_cr_count.allegra = Object.values(response.data.allegra_paid_cash_registers);
            this.total = this.chart_cr_count.aria.map((value, index) => value + this.chart_cr_count.allegra[index])
            this.chart_cr_count.total = this.chart_cr_count.aria.map((value, index) => value + this.chart_cr_count.allegra[index])
            this.chart_cr_count.key= this.chart_cr_count.key_label+'_'+this.increment;
            this.increment++;

            this.apiCallCount--; // Decrement the counter

            if (this.apiCallCount === 0) {
              this.isLoading = false; // Hide the loader when all API calls are complete
            }

          })
          .catch(error => {
            console.log(error);
            this.$snackbar(this.$t('efi_transactions.error_string'), 'error');
            this.apiCallCount--; // Decrement the counter

            if (this.apiCallCount === 0) {
              this.isLoading = false; // Hide the loader when all API calls are complete
            }
          })
    },
    getCashRegisterActivatedData() {
      this.apiCallCount++; // Increment the counter for each API call

      this.isLoading = true; // Show the loader
      ApiService.get('/admin/cash-registers/activated/stats', {
          params: {
              date: this.date,
              contractIds: this.selectedItems,
              resellerIds: this.selectedResellers
          }
      })
          .then(response => {

            this.chart_cr_activated.labels = Object.keys(response.data.aria_activated_cash_registers);
            this.chart_cr_activated.aria = Object.values(response.data.aria_activated_cash_registers);
            this.chart_cr_activated.allegra = Object.values(response.data.allegra_activated_cash_registers);
            this.chart_cr_activated.total = this.chart_cr_activated.aria.map((value, index) => value + this.chart_cr_activated.allegra[index])
            this.apiCallCount--; // Decrement the counter

            this.chart_cr_activated.key= this.chart_cr_activated.key_label+'_'+this.increment;
            this.increment++;
            if (this.apiCallCount === 0) {
              this.isLoading = false; // Hide the loader when all API calls are complete
            }

          })
          .catch(error => {
            console.log(error);

            this.$snackbar(this.$t('efi_transactions.error_string'), 'error');
            this.apiCallCount--; // Decrement the counter

            if (this.apiCallCount === 0) {
              this.isLoading = false; // Hide the loader when all API calls are complete
            }
          })
    },
    getCashRegisterDeactivatedData() {
      this.apiCallCount++; // Increment the counter for each API call

      this.isLoading = true; // Show the loader
      ApiService.get('/admin/cash-registers/deactivated/stats', {
          params: {
              date: this.date,
              contractIds: this.selectedItems,
              resellerIds: this.selectedResellers
          }
      })
          .then(response => {

            this.chart_cr_deactivated.labels = Object.keys(response.data.aria_deactivated_cash_registers);
            this.chart_cr_deactivated.aria = Object.values(response.data.aria_deactivated_cash_registers);
            this.chart_cr_deactivated.allegra = Object.values(response.data.allegra_deactivated_cash_registers);
            this.chart_cr_deactivated.total = this.chart_cr_deactivated.aria.map((value, index) => value + this.chart_cr_deactivated.allegra[index])
            this.chart_cr_deactivated.key= this.chart_cr_deactivated.key_label+'_'+this.increment;
            this.increment++;

            this.apiCallCount--; // Decrement the counter

            if (this.apiCallCount === 0) {
              this.isLoading = false; // Hide the loader when all API calls are complete
            }

          })
          .catch(error => {
            console.log(error);

            this.$snackbar(this.$t('general.error'), 'error');
            this.apiCallCount--; // Decrement the counter

            if (this.apiCallCount === 0) {
              this.isLoading = false; // Hide the loader when all API calls are complete
            }
          })
    },
    getCashRegisterDeactivatedDataWhereDatesDontMatch() {
      this.apiCallCount++; // Increment the counter for each API call

      this.isLoading = true; // Show the loader
      ApiService.get('/admin/cash-registers/deactivated/stats/not-matching-dates', {
          params: {
              date: this.date,
              contractIds: this.selectedItems,
              resellerIds: this.selectedResellers
          }
      })
          .then(response => {

              this.chart_cr_contract_deactivated_not_matched.labels = Object.keys(response.data.aria_deactivated_cash_registers_where_dates_dont_match);
              this.chart_cr_contract_deactivated_not_matched.aria = Object.values(response.data.aria_deactivated_cash_registers_where_dates_dont_match);
              this.chart_cr_contract_deactivated_not_matched.allegra = Object.values(response.data.allegra_deactivated_cash_registers_where_dates_dont_match);
              this.chart_cr_contract_deactivated_not_matched.total = this.chart_cr_contract_deactivated_not_matched.aria.map((value, index) => value + this.chart_cr_contract_deactivated_not_matched.allegra[index])
              this.chart_cr_contract_deactivated_not_matched.key= this.chart_cr_contract_deactivated_not_matched.key_label+'_'+this.increment;
              this.increment++;

              this.apiCallCount--; // Decrement the counter

              if (this.apiCallCount === 0) {
                  this.isLoading = false; // Hide the loader when all API calls are complete
              }

          })
          .catch(error => {
              console.log(error);

              this.$snackbar(this.$t('general.error'), 'error');
              this.apiCallCount--; // Decrement the counter

              if (this.apiCallCount === 0) {
                  this.isLoading = false; // Hide the loader when all API calls are complete
              }
          })
    },
    getCashRegisterActivatedDataWhereDatesDontMatch() {
      this.apiCallCount++; // Increment the counter for each API call

      this.isLoading = true; // Show the loader
      ApiService.get('/admin/cash-registers/activated/stats/not-matching-dates', {
          params: {
              date: this.date,
              contractIds: this.selectedItems,
              resellerIds: this.selectedResellers
          }
      })
          .then(response => {

              this.chart_cr_contract_activated_not_matched.labels = Object.keys(response.data.aria_activated_cash_registers_where_dates_dont_match);
              this.chart_cr_contract_activated_not_matched.aria = Object.values(response.data.aria_activated_cash_registers_where_dates_dont_match);
              this.chart_cr_contract_activated_not_matched.allegra = Object.values(response.data.allegra_activated_cash_registers_where_dates_dont_match);
              this.chart_cr_contract_activated_not_matched.total = this.chart_cr_contract_activated_not_matched.aria.map((value, index) => value + this.chart_cr_contract_activated_not_matched.allegra[index])
              this.chart_cr_contract_activated_not_matched.key= this.chart_cr_contract_activated_not_matched.key_label+'_'+this.increment;
              this.increment++;

              this.apiCallCount--; // Decrement the counter

              if (this.apiCallCount === 0) {
                  this.isLoading = false; // Hide the loader when all API calls are complete
              }

          })
          .catch(error => {
              console.log(error);

              this.$snackbar(this.$t('general.error'), 'error');
              this.apiCallCount--; // Decrement the counter

              if (this.apiCallCount === 0) {
                  this.isLoading = false; // Hide the loader when all API calls are complete
              }
          })
    },
    getResellers() {

      ApiService.get('/admin/resellers')
          .then(response => {
            this.resellers = response.data.resellers;
          })
          .catch(error => {
            console.log(error);
          })
    }
  }
}


</script>
<style scoped>
.stat {
  padding: 20px;
  background: white;
  border-radius: 4px;
  border: 1px solid #d3d5dc;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
.loading-text {
  position: fixed;
  top: 10%;
  left: 50%;
}
.loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 4px solid #fff;
  border-top-color: #000; /* Customize the color of the loader */
  animation: loader-rotation 1s linear infinite; /* Customize the animation duration and easing */
}
@keyframes loader-rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
