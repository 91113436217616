var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"stat"},[(_vm.isLoading)?_c('div',{staticClass:"overlay"},[_c('div',{staticClass:"loading-text loader"})]):_vm._e(),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-select',{attrs:{"items":_vm.resellers,"item-text":"name","item-value":"id","label":_vm.$t('statistics.resellers'),"multiple":"","append-icon":"mdi mdi-chevron-down","prepend-icon":"mdi mdi-account-tie"},model:{value:(_vm.selectedResellers),callback:function ($$v) {_vm.selectedResellers=$$v},expression:"selectedResellers"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"label":_vm.$t('statistics.select_date_range'),"readonly":"","prepend-icon":"mdi mdi-calendar"},on:{"click":_vm.openDialog},model:{value:(_vm.dateShow),callback:function ($$v) {_vm.dateShow=$$v},expression:"dateShow"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mt-3",attrs:{"outlined":""},on:{"click":_vm.getBillingData}},[_c('v-icon',[_vm._v("mdi mdi-filter-outline")]),_vm._v("\n                "+_vm._s(_vm.$t('statistics.filter'))+"\n            ")],1)],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"5"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-stat',{attrs:{"value":_vm.totalInvoiced,"icon":"mdi mdi-cash-multiple","backgroundColor":"black","label":_vm.$t('statistics.total_invoiced'),"aria":_vm.ariaInvoices,"allegra":_vm.allegraInvoices,"currency":_vm.currency}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('statistics.total_invoiced_details')))])])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-stat',{attrs:{"value":_vm.totalPaid,"label":_vm.$t('statistics.total_paid'),"icon":"mdi mdi-currency-usd","backgroundColor":"green","currency":_vm.currency}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('statistics.total_paid_details')))])])],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g({},on),[_c('v-stat',{attrs:{"value":_vm.totalDebt,"label":_vm.$t('statistics.total_debt'),"icon":"mdi mdi-currency-usd-off","backgroundColor":"red","currency":_vm.currency}})],1)]}}])},[_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.$t('statistics.total_debt_details')))])])],1)],1),_vm._v(" "),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('Chart',{key:_vm.chart_billing.key,attrs:{"currency":_vm.currency,"chart-name":"Billing Info","props-labels":_vm.chart_billing.labels,"orders-aria":_vm.chart_billing.aria,"orders-allegra":_vm.chart_billing.allegra,"orders-total":_vm.chart_billing.total,"calculationLabels":_vm.calculationLabels,"header-text":_vm.$t('statistics.billing')}})],1)],1),_vm._v(" "),_c('v-dialog',{attrs:{"width":"350"},model:{value:(_vm.show.dialog),callback:function ($$v) {_vm.$set(_vm.show, "dialog", $$v)},expression:"show.dialog"}},[_c('div',[_c('v-card',[_c('div',{staticClass:"dialog-header"},[_vm._v("\n                    "+_vm._s(_vm.$t('companies.payments.dialog'))+"\n                ")]),_vm._v(" "),_c('div',{staticStyle:{"padding":"4%"}},[_c('div',{staticStyle:{"text-align":"center"}},[_c('v-date-picker',{attrs:{"label":"Date","max":_vm.currentDate,"range":""},on:{"input":_vm.validateDateRange},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)])])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }