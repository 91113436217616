<template>
    <v-snackbar
        v-model="snackbar.show"
        :bottom="snackbar.y === 'bottom'"
        :left="snackbar.x === 'left'"
        :multi-line="snackbar.mode === 'multi-line'"
        :right="snackbar.x === 'right'"
        :timeout="snackbar.timeout"
        :top="snackbar.y === 'top'"
        :color="snackbar.color"
        :vertical="snackbar.mode === 'vertical'"
    >
        {{ snackbar.text }}

        <template v-slot:action="{ attrs }">
            <v-btn
                v-if="snackbar.buttonText"
                :color="snackbar.color ? 'white' : 'info'"
                text
                v-html="snackbar.buttonText"
                @click="snackbar.clickHandler"
            />

            <v-btn
                text
                :color="snackbar.color ? 'white' : 'info'"
                @click="snackbar.show = false"
            >
                <v-icon v-if="snackbar.buttonText">mdi mdi-delete-outline</v-icon>
                <span v-else>Close</span>
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
    export default {

        created() {
            EventBus.$on('show-snackbar', (text, type = null, options = {}) => {
                this.show(text, type, options);
            });
        },

        data() {
            return {
                snackbar: { show: false },
            }
        },

        methods: {
            show(text, type, options = {}) {
                let delay = this.snackbar.show ? 500 : 0;
                this.snackbar.show = false;

                setTimeout(() => {
                    this.snackbar.x = options.x ? options.x : 'right';
                    this.snackbar.y = options.y ? options.y : 'top';
                    this.snackbar.mode = options.mode ? options.mode : '';
                    this.snackbar.timeout = options.timeout || options.timeout == 0 ? options.timeout : 6000;
                    this.snackbar.color = type;
                    this.snackbar.text = text;
                    this.snackbar.show = true;
                    this.snackbar.buttonText = options.buttonText;
                    this.snackbar.clickHandler = options.clickHandler
                        ? () => {
                            options.clickHandler();
                            this.snackbar.show = false;
                        }
                        : () => (this.snackbar.show = false);
                }, delay);
            }
        },
    }
</script>

<style lang="scss" scoped>
    .wrapper {
        width: 60px;
        height: 60px;
        border-spacing: 0 !important;
        border-collapse: collapse !important;

        .cell {
            cursor: pointer;
            border: 1px solid #8a94a0;
            background: #f3f3f4;

            &:hover {
                background: #d3d9e1;
            }

            &.selected {
                background: #909399;
            }
        }
    }

    .v-snack--top {
        top: 5px;
    }
</style>
