// Vue
import Vue from 'vue';
window.Vue = Vue;

// Vue Router
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// Global Event Bus
window.EventBus = new Vue();

// Lodash
import lodash from 'lodash';
Object.defineProperty(Vue.prototype, '_', { value: lodash });
window._ = lodash;

// Moment
import Moment from 'moment';
window.moment = Moment;

// Form
import CodForm from '@/forms/CodForm';
Object.defineProperty(Vue.prototype, 'CodForm', { value: CodForm });
window.CodForm = CodForm;

// Vuelidate
import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

// Api Service
import ApiService from '@/services/ApiService';
window.ApiService = ApiService;
ApiService.init();