<template>
    <div class="unfiscalized-bills">
        <v-row class="mb-4">
            <v-col cols="12" sm="5">
                <v-text-field
                    v-model="filter"
                    :label="$t('tenant.search')"
                    append-icon="far fa-search"
                    clearable
                    clear-icon="mdi mdi-magnify"
                    single-line
                    hide-details
                    @input="filterUnfiscalizedBills()"
                />
            </v-col>

            <v-col class="d-flex align-end justify-end" cols="2" sm="1">
                <v-btn
                    icon
                    @click="getUnfiscalizedBills()"
                >
                    <v-icon>mdi mdi-refresh</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            class="operations striped"
            :headers="headers"
            :items="unfiscallizedBills"
            :options.sync="pagination"
            :server-items-length="total"
            :footer-props="{'items-per-page-options': [20, 50, 100, 200]}"
            :items-per-page.sync="pagination.itemsPerPage"
            :loading="loading"
            :mobile-breakpoint="0"
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td>{{ item.tenant.name }}</td>
                    <td>{{ item.tenant.email }}</td>
                    <td>{{ item.number }}</td>
                    <td>{{ item.hash }}</td>
                    <td>{{ item.last_activity_at | dateFromUtc }}</td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    created() {
    },

    data() {
        return {
            loading: false,

            total: 0,
            pagination: {
                sortBy: ['created_at'],
                itemsPerPage: 100,
                page: 1,
                sortDesc: [true]
            },

            headers: [
                { text: this.$t('tenant.name'), value: 'tenant.name' },
                { text: this.$t('general.email'), value: 'tenant.email' },
                { text: this.$t('tenant.order_number'), value: 'tenant.order_number' },
                { text: this.$t('tenant.hash'), value: 'tenant.hash' },
                { text: this.$t('tenant.last_activity_at'), value: 'tenant.last_activity_at' },
            ],

            filter: null,
            unfiscallizedBills: [],
            curTransaction: null
        };
    },

    watch: {
        pagination: {
            deep: true,
            handler() {
                this.getUnfiscalizedBills();
            },
        },
    },

    methods: {
        /**
         * Get Paginated, Filtered and Sorted unfiscallizedBills from server
         */
        getUnfiscalizedBills() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : 'created_at',
                sortOrder: sortDesc[0] ? 'desc' : 'asc',
            }

            if(this.filter) params['filter'] = this.filter

            this.loading = true;
            ApiService.get('/admin/unregistered-orders', { params: params }).then(response => {
                this.loading = false;
                this.unfiscallizedBills = response.data.data;
                this.total = response.data.total;
            });
        },

        /**
         * Filter Unfiscalized bills
         */
        filterUnfiscalizedBills: _.debounce(function() {
            this.getUnfiscalizedBills();
        }, 500),

    }
};
</script>

<style lang="scss" scoped>
.unfiscalized-bills {
    padding: 20px;
    background: white;
    border-radius: 4px;
    border: 1px solid #d3d5dc;
}
</style>
