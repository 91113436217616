<template>
    <div class="notifications">
        <v-row class="mb-4">
            <v-col cols="6" sm="6">
                <v-text-field
                    v-model="email_filter"
                    :label="$t('general.searchByEmail')"
                    append-icon="far fa-search"
                    clearable
                    clear-icon="mdi mdi-magnify"
                    single-line
                    hide-details
                    @input="filterNotifications()"
                />
            </v-col>
            <v-col align="right">
                <v-btn
                    outlined
                    @click="show.createNotification = true;"
                >
                    <v-icon>mdi mdi-plus</v-icon>
                    {{$t('general.create')}}
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            class="operations striped"
            :headers="headers"
            :items="notifications"
            :options.sync="pagination"
            :server-items-length="total"
            :footer-props="{'items-per-page-options': [20, 50, 100, 200, 500, 1000]}"
            :items-per-page.sync="pagination.itemsPerPage"
            :loading="loading"
            :mobile-breakpoint="0"
        >

            <template v-slot:item="{headers, item, index }">
                <tr>
                    <td>{{ item.text != null ? item.text.substring(0,80) : item.text }}</td>
                    <td>{{ item.tenant.email }}</td>
                    <td>{{ item.created_at | dateFromUtc }}</td>
                    <td>{{ item.read_at | dateFromUtc }}</td>
                    <td>{{ item.code }}</td>
                    <td class="w60" align="center">
                        <v-menu bottom transition="slide-y-transition">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon>
                                    <v-icon >mdi mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <template>
                                    <v-list-item
                                        :key="1"
                                        @click="showDetails(item)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-eye-outline</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>{{$t('general.details')}}</v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>

                    </td>
                </tr>
            </template>
        </v-data-table>

        <!-- Create Notification dialog -->
        <v-dialog
            v-model="show.createNotification"
            width="500"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('notifications.create')}}
                </div>
                <NotificationForm
                    @save="onNotificationCreate"
                />
        </div>
        </v-dialog>

        <v-dialog
            v-model="show.details"
            width="500"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('general.details')}}
                </div>
                <div class="dialogText">
                    <div class="ma-4">
                       {{ this.details.text }}
                    </div>
                    <div class="ma-4">
                       {{ $t('notifications.created_at') }} : {{ this.details.created_at | dateFromUtc }}
                    </div>
                   <div class="ma-4">
                       {{ $t('notifications.read_at') }} : {{ this.details.read_at | dateFromUtc }}
                   </div>

                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import _ from "lodash";
import NotificationForm from "@/views/admin/Notifications/NotificationForm.vue";

export default {
    components: {
        NotificationForm,
    },
    data() {
        return {
            details:{
                text:null,
                created_at:null,
                read_at:null,
            },
            loading: false,
            total: 0,
            pagination: {
                sortBy: ["created_at"],
                itemsPerPage: 100,
                page: 1,
                sortDesc: [true],
            },

            headers: [
                { text: this.$t('general.text'), value: "text" },
                { text: this.$t('notifications.receiver'), value: "tenant.email",  sortable: false },
                { text: this.$t('notifications.created_at'), value: "created_at",  sortable: true },
                { text: this.$t('notifications.read_at'), value: "read_at",  sortable: true },
                { text: this.$t('notifications.code'), value: "code",  sortable: true },
            ],

            email_filter: null,
            notifications: [],
            show: {
                createNotification: false,
                details: false,
            },
        };
    },

    watch: {
        pagination: {
            deep: true,
            handler() {
                this.getNotifications();
            },
        },
    },
    methods: {

        /**
         * Get Paginated, Filtered and Sorted transactions from server
         */
        getNotifications() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : "created_at",
                sortOrder: sortDesc[0] ? "desc" : "asc",
            };

            if (this.email_filter) params["email_filter"] = this.email_filter;
            this.loading = true;

            ApiService.get("/admin/notifications", { params: params }).then(
                response => {
                    this.loading = false;
                    this.notifications = response.data.notifications.data.map((notification) => {
                        return notification;
                    });
                    this.total = response.data.notifications.total;
                }
            );
        },
        /**
         * Show details of notification
         */
        showDetails(details){
            this.details = details
            this.show.details = true;
        },
        /**
         * Filter Notifications
         */
        filterNotifications: _.debounce(function () {
            this.getNotifications();
        }, 500),

        onNotificationCreate() {
            this.show.createNotification = false;
            this.getNotifications();
        },
    },
};
</script>

<style lang="scss" scoped>
.notifications {
    padding: 20px;
    background: white;
    border-radius: 4px;
    border: 1px solid #d3d5dc;
}
.dialogText{
    background: white;
    padding: 20px;
}
.dialog {
    .dialog-header {
        padding: 16px;
        background: #363c4a;
        color: white;
    }

    .dialog-content {
        padding: 16px;
        background: white;
    }
}
.sum-td{
    font-weight:bold;
    font-size: 20px !important;
}
</style>
