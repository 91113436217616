<template>
    <div>
     <v-form
        v-if="form"
        ref="form"
        class="venue-form"
    >
        <v-text-field
            v-model="form.name"
            :error-messages="form.$errors.name"
            :label="$t('general.name')"
            required
        />

        <v-text-field
            v-model="form.tax_id_number"
            :error-messages="form.$errors.tax_id_number"
            :label="$t('general.tax_id_number')"
            required
        />

        <v-text-field
            v-model="form.email"
            :error-messages="form.$errors.email"
            :label="$t('general.email')"
            required
        />

        <v-text-field
            v-model="form.registration_number"
            type="number"
            :error-messages="form.$errors.registration_number"
            :label="$t('general.registration_number')"
            required
        />

        <v-text-field
            v-model="form.phone"
            :error-messages="form.$errors.phone"
            :label="$t('general.phone')"
            required
        />

        <v-text-field
            v-model="form.address"
            :error-messages="form.$errors.address"
            :label="$t('general.adress')"
            required
        />

        <v-text-field
            v-model="form.city"
            :error-messages="form.$errors.city"
            :label="$t('general.city')"
            required
        />

        <v-text-field
            v-model="form.postal_number"
            :error-messages="form.$errors.postal_number"
            :label="$t('general.postal_number')"
            required
        />

        <v-text-field
            v-model="form.contact_person"
            :error-messages="form.$errors.contact_person"
            :label="$t('general.contact_person')"
            required
        />
        <v-select
            v-model="form.tier"
            :error-messages="form.$errors.tier"
            :items="['A', 'B', 'C', 'A0']"
            :label="$t('general.tier')"
        >
        </v-select>
        <v-autocomplete
            v-if="$store.state.user.role === 'admin' && action === 'post'"
            :error-messages="autoCompleteValidation  ? 'The field is required': null"
            v-model="creationReseller"
            :label="$t('general.reseller')"
            :items="autoCompleteResellers"
            item-text="state"
            item-value="abbr"
            persistent-hint
            return-object
            single-line
            clearable
            @change="resellerRequired = false"
        >
        </v-autocomplete>

        <v-select
          v-model="form.country"
          :items="['ME', 'RS']"
          :label="$t('general.country')"
        >
        </v-select>

        <div class="d-flex justify-center mb-3">
            <v-btn
                v-if="action === 'patch'"
                color="primary"
                outlined
                @click="openTierWarningDialog"
            >
            <span>
                {{$t('general.edit')}}
            </span>
            </v-btn>

            <v-btn
                v-if="action === 'post'"
                color="primary"
                outlined
                @click="save"
            >
             <span>
                {{$t('general.create')}}
            </span>
            </v-btn>
        </div>

    </v-form>
        <v-dialog
            v-model="warningDialog"
            width="500"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('general.warning')}}
                </div>
                <div class="dialog-body">
                    {{$t('resellers.tierChangeMessage')}}

                    <div class="d-flex justify-space-between">
                        <v-btn
                            class="mt-7 align-center"
                            width="200px"
                            color="red"
                            outlined
                            @click="warningDialog = false"
                        >
                            {{ this.$t('notifications.cancel') }}
                        </v-btn>
                        <v-btn
                            class="mt-7 align-center"
                            width="200px"
                            color="primary"
                            outlined
                            @click="save"
                        >
                            {{$t('general.edit')}}
                        </v-btn>
                    </div>
                </div>
            </div>

        </v-dialog>
    </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import * as rules from "vuelidate/lib/validators";

export default {
    props: {
        reseller: {
            type: Object,
            default: () => null,
        },
        action: {
            type: String,
            default: () => 'patch',
        }
    },
    created(){this.getResellers()},
    data() {
        return {
            form: this.createForm(this.reseller),
            resellers: [],
            creationReseller: null,
            autoCompleteResellers: [],
            autoCompleteValidation: false,
            warningDialog: false
        };
    },
    validations: {
        form: {
            name: { required: rules.required },
            tax_id_number: { required: rules.required },
        },
    },
    watch: {
        //Watching prop changes to create form with new data
        reseller: {
            deep: true,
            immediate: true,
            handler(newReseller) {
                this.form = this.createForm(this.reseller)
            },
        },
    },
    methods: {
        createForm(reseller = null) {
            return new CodForm(this, "form", {
                id: reseller ? reseller.id : null,
                name: reseller ? reseller.name : null,
                tax_id_number: reseller ? reseller.tax_id_number : null,
                email: reseller ? reseller.email : null,
                phone: reseller ? reseller.phone : null,
                address: reseller ? reseller.address : null,
                city: reseller ? reseller.city : null,
                postal_number: reseller ? reseller.postal_number : null,
                country: reseller ? reseller.country : 'RS',
                contact_person: reseller ? reseller.contact_person : null,
                registration_number: reseller ? reseller.registration_number : null,
                parent_id: this.creationReseller ? this.creationReseller: null,
                tier: this.reseller ? this.reseller.tier: null
            });
        },
        getResellers(){
            ApiService.get("/admin/resellers?locale=sr").then(
                response => {
                    response.data.resellers.forEach(reseller => this.resellers.push({ id: reseller.id, name: reseller.name }))
                    this.autoCompleteResellers = this.resellers.map(res => res.name);
                }

            );
        },
        /**
         * Save Reseller
         */
        save() {


            if (!this.form.validate()) return;


            if (this.action === 'post' && this.$store.state.user.role === 'admin') {
                if (!this.creationReseller) {
                    this.autoCompleteValidation = true;
                    return;
                }

                this.autoCompleteValidation = false;
                const resellerId = this.resellers.filter(res => res.name === this.creationReseller)[0].id;
                this.form.parent_id = resellerId;
            }

            const url = this.action === 'patch' ? `/admin/resellers/${this.form.id}` : '/admin/resellers';

            ApiService[this.action](url, this.form).then(
                (response) => {
                    this.$emit("save", response.data);
                    const actionMessage = this.action === 'post' ? 'created' : 'updated'
                    this.$snackbar(this.$t(`resellers.${actionMessage}`), "success");
                }
            );

        },

        openTierWarningDialog(){
            //If tier is not changed continue to save
            if(this.reseller.tier == this.form.tier){
                return this.save()
            }
            this.warningDialog = true
        }

    },
};
</script>

<style lang="scss" scoped>
</style>
