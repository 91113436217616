<template>
    <v-navigation-drawer
        v-model="drawer"
        width="260"
        :mobile-breakpoint="960"
        dark
        fixed
        app
    >
        <div class="admin-navigation-drawer">
            <div class="sidebar-container">

                <div class="logo-section">
                    <img src="https://ariapos.me/img/logoDarkHor.7ea73bbe.svg" alt="">
                </div>

                <v-list>
                    <template v-for="menuItem in filteredMenuItems" v-if="menuItem.showRoute">

                        <v-list-item
                            v-if="!menuItem.children"
                            :key="menuItem.route"
                            class="menu-item"
                            :class="{ selected: $route.name === menuItem.route }"
                            @click="goToRoute(menuItem.route, menuItem.display)"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ menuItem.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title class="menu-item__title">
                                    {{ $t(menuItem.display) }}
                                </v-list-item-title>
                            </v-list-item-content>

                        </v-list-item>
                        <v-list-group
                            v-else
                            :key="menuItem.section"
                            v-model="menuItem.active"
                            :prepend-icon="menuItem.icon"
                            class="menu-section"
                            no-action
                        >
                            <template v-slot:activator>
                                <v-list-item-title>{{ menuItem.section }}</v-list-item-title>
                            </template>

                            <v-list-item
                                v-for="submenuItem in menuItem.children"
                                :key="submenuItem.route"
                                class="menu-item"
                                @click="goToRoute(submenuItem.route, submenuItem.display)"
                            >
                                <v-icon class="mr-3">{{ submenuItem.icon }}</v-icon>

                                <v-list-item-content>
                                    <v-list-item-title
                                        class="menu-item__title"
                                        :class="{ selected: $route.name === submenuItem.route }"
                                    >
                                        {{ submenuItem.display }}
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>

                        </v-list-group>

                    </template>
                    <!-- <v-switch
                        @change="switchLanguage($event)"
                        :label="$t('general.switch_language')"
                        style="margin-left:5%"
                    ></v-switch> -->
                </v-list>
            </div>
        </div>
    </v-navigation-drawer>
</template>

<script>
// import i18n from '@/i18n'

export default {
    created() {
        this.menuItems.forEach(menuItem => {
            let activeItem = null;
            if(menuItem.route === this.$route.name){
                activeItem = menuItem;
            } else if(menuItem.children) {
                activeItem = menuItem.children.find(item => item.route === this.$route.name);
            }

            if (activeItem) {
                EventBus.$emit('route-update', { title: activeItem.display });
            }
        });

        EventBus.$on('admin-toggle-drawer', () => {
            this.drawer = !this.drawer;
        });
    },

    data() {
        return {
            drawer: this.$vuetify.breakpoint.mdAndUp,
        };
    },

    computed: {
        menuItems() {
            return [
                {
                    section: this.$t('admin_navigation.statistics'),
                    icon: 'mdi mdi-chart-pie',
                    showRoute: this.$store.state.user.role === 'admin' || this.$store.state.user.role === 'main_reseller',
                    children: [
                        {
                            icon: 'mdi mdi-chart-scatter-plot-hexbin',
                            display: this.$t('admin_navigation.customers'),
                            route: 'statistics',
                            enabled: true,
                        },
                        {
                            icon: 'mdi mdi-finance',
                            display: this.$t('admin_navigation.billing'),
                            route: 'billingstatistics',
                            enabled: true,
                        },
                    ],
                    active: ['billingstatistics','statistics'].includes(this.$route.name)
                },
                {
                    section: this.$t('admin_navigation.customers'),
                    showRoute: true,
                    icon: 'mdi mdi-account-multiple-outline',
                    children: [
                        {
                            icon: 'mdi mdi-domain',
                            display: this.$t('admin_navigation.companies'),
                            route: 'allcompanies',
                            enabled: true,
                        },
                        {
                            icon: 'mdi mdi-account-multiple-outline',
                            display: this.$t('admin_navigation.customers'),
                            route: 'customers',
                            enabled: true,
                        },
                        {
                            icon: 'mdi mdi-account-tie',
                            display: this.$t('admin_navigation.resellers'),
                            route: 'resellers',
                            enabled: this.$store.state.user.role === 'admin' || this.$store.state.user.role === 'main_reseller',
                        },
                    ],
                    active: ['allcompanies','customers','resellers'].includes(this.$route.name)
                },
                {
                    section: this.$t('admin_navigation.fiscalization'),
                    showRoute: this.$store.state.user.role === 'admin' || this.$store.state.user.role === 'main_reseller',
                    icon: 'mdi mdi-cloud-print-outline',
                    children: [
                        {
                            icon: 'mdi mdi-swap-vertical',
                            display: this.$t('admin_navigation.efi_transactions'),
                            route: 'efi-transactions',
                            enabled: this.$store.state.user.role === 'admin',
                        },
                        {
                            icon: 'mdi mdi-message-alert',
                            display: this.$t('admin_navigation.unfiscalized_bills'),
                            route: 'unfiscalizedbills',
                            enabled: this.$store.state.user.role === 'admin',
                        },
                        {
                            icon: 'mdi mdi-consolidate',
                            display: this.$t('admin_navigation.lpfr_table'),
                            route: 'lpfrTable',
                            enabled: this.$store.state.user.role === 'admin' || this.$store.state.user.role === 'main_reseller'
                        },
                    ],
                    active: ['efi-transactions','unfiscalizedbills','lpfrTable'].includes(this.$route.name)
                },
                {
                    section: this.$t('admin_navigation.billing'),
                    showRoute: this.$store.state.user.role === 'admin' || this.$store.state.user.role === 'main_reseller',
                    icon: 'mdi mdi-cash-usd',
                    children: [
                        {
                            icon: 'mdi mdi-file-document-edit',
                            display: this.$t('admin_navigation.contracts'),
                            route: 'contracts',
                            enabled: this.$store.state.user.role === 'admin' || this.$store.state.user.role === 'main_reseller'
                        },
                        {
                            icon: 'mdi mdi-package-variant',
                            display: this.$t('admin_navigation.modules'),
                            route: 'modules',
                            enabled: this.$store.state.user.role === 'admin' || this.$store.state.user.role === 'main_reseller'
                        },
                        {
                            icon: 'mdi mdi-buffer',
                            display: this.$t('admin_navigation.integrations'),
                            route: 'integrations',
                            enabled: this.$store.state.user.role === 'admin' || this.$store.state.user.role === 'main_reseller'
                        },
                        {
                            icon: 'mdi mdi-bank',
                            display: this.$t('admin_navigation.bank_statements'),
                            route: 'bankStatements',
                            enabled: this.$store.state.user.role === 'admin' || this.$store.state.user.role === 'main_reseller'
                        },
                    ],
                    active: ["contracts","integrations","modules","bankStatements"].includes(this.$route.name)
                },
                {
                    section: this.$t('admin_navigation.other'),
                    showRoute: true,
                    icon: 'mdi mdi-dots-horizontal-circle',
                    children: [
                        {
                            icon: 'mdi mdi-bell-ring',
                            display: this.$t('admin_navigation.notifications'),
                            route: 'notifications',
                            enabled: true
                        },
                        {
                            icon: 'mdi mdi-account-key',
                            display: this.$t('admin_navigation.users'),
                            route: 'users',
                            enabled: this.$store.state.user.role === 'admin'
                        },
                    ],
                    active: ["notifications","users"].includes(this.$route.name)
                },
            ];
        },

        filteredMenuItems() {
            return _.cloneDeep(this.menuItems)
                .map(menuItem => {
                    if(menuItem.children){
                        menuItem.children = menuItem.children.filter(item => item.enabled);
                    }

                    return menuItem;
                })
                .filter(menuItem => menuItem.enabled || (menuItem.children && menuItem.children.length));
        }
    },

  methods: {
        goToRoute(route, display) {
            if(this.$route.name === route){
                return;
            }

            EventBus.$emit('route-update', { title: display });
            this.$router.push({ name: route });
        },
        // switchLanguage(event){
        //     event ? i18n.locale = 'me' : i18n.locale = 'en';
        // }
    }
};
</script>

<style lang="scss">

.admin-navigation-drawer {
    background: #20222e;
    height: 100%;

    .sidebar-container {
        height: 100%;

        .logo-section {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 55px;

            img {
                height: 35px;
            }

            @media screen and (min-width: 960px) {
                height: 63px;
            }
        }

        .v-list {
            background: transparent;
        }

        .menu-section {
            .v-list-item--active {
                color: #fff;
            }

            &.v-list-group--active {
                border-left: 4px solid #82B1FF;
                background: #282b38 !important;
            }

            .menu-item {
                padding-left: 72px !important;

                .menu-item__title {
                    &.selected {
                        color: #82B1FF !important;
                    }
                }
            }
        }

        .menu-item {
            &.selected {
                background: #282b38 !important;

                .menu-item__title {
                    color: #82B1FF !important;
                }
            }
        }
    }
}
</style>
