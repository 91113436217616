<template>
    <div>
        <v-timeline v-if="logs.length > 0">
            <v-timeline-item
                color="black"
                v-for="log in logs"
                :key="log.id"
            >
                <span slot="opposite">{{ log.created_at | dateFromUtc }}</span>
                <v-card class="elevation-2">
                    <v-card-title class="text-h6">
                        {{ log.description }}
                    </v-card-title>
                    <v-card-text>
                        <div v-if="log.description == 'created'">
                            <div>
                                {{ $t('general.user') + " " +  (log.causer ? log.causer.email : 'system') + " " + $t('log.is_created') }}
                            </div>
                            <vue-json-pretty :data="log.properties.attributes" />
                        </div>
                        <div v-if="log.description == 'updated'">
                            <div>
                                {{ $t('general.user') + " " +  (log.causer ? log.causer.email : 'system') + " " + $t('log.is_updated') }}
                            </div>
                            <vue-json-pretty :data="log.properties.old" />
                            {{ $t('log.to') }}
                            <vue-json-pretty :data="log.properties.attributes" />
                        </div>
                        <div v-if="log.description == 'deleted'">
                            {{ $t('general.user') + " " +   (log.causer ? log.causer.email : 'system') + " " + $t('log.is_deleted') }}
                            <vue-json-pretty :data="log.properties.attributes" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-timeline-item>
        </v-timeline>
        <div v-else > {{$t('log.no_logs')}} </div>
    </div>
</template>


<script>
import VueJsonPretty from "vue-json-pretty";
export default {
    components: {VueJsonPretty},
    props: {
        logs: {
            type: Array,
        }
    }
}
</script>
