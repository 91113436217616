<template>
    <div>
        <div class="dialog-header">
            {{$t('general.terminate_contract_to')}} <b>{{ selectedContract.tenant.email }}</b>
        </div>
        <v-card>
            <div style="padding:2%">
                <p>{{$t('general.package_name')}}: {{ selectedContract.contract.name }}</p>
                <p>{{$t('contracts.quantity')}}: {{ selectedContract.quantity }}</p>
                <p>{{$t('contracts.discount')}}: {{ selectedContract.discount }}</p>
                <p v-if="selectedContract.terminated_at"> {{$t('general.terminated')}}: {{ selectedContract.terminated_at | dateFromUtc }}</p>
                <p>{{ selectedContract.started_at | dateFromUtc}} → {{ selectedContract.expires_at | dateFromUtc}} </p>
                <v-text-field
                    v-model="terminationReason"
                    :label="$t('contracts.termination_reason')"
                    required
                />
                <div style="text-align:center">
                    <v-date-picker 
                        v-if="!selectedContract.terminated_at"
                        v-model="dateOfTermination"
                        label="Date"
                        :type="$i18n.locale === 'sr' ? 'month' : 'date'"
                        >
                    </v-date-picker>
                </div>
            </div>
            <v-card-actions class="justify-center">
                <v-btn
                    outlined
                    color="blue"
                    @click="terminateContract(selectedContract.id)"
                    v-if="!selectedContract.terminated_at"
                >
                    {{$t('general.terminate')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: "TerminateContract",
    props:[
        "selectedContract",
    ],
    data(){
        return{
            terminationReason: "",
            dateOfTermination: i18n.locale === 'me' ? 
                                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
                            :   (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 8) + '01'
        }
    },
    methods:{
        terminateContract(id){
            this.$emit("terminateContract", {
                id: id,
                terminationReason: this.terminationReason,
                terminated_at: this.dateOfTermination
            })
            this.terminationReason = "";
            this.dateOfTermination = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
        }
    }
};
</script>

<style></style>
