<template>
  <div>
    <v-app-bar
        class="admin-app-bar elevation-0"
        app
    >
        <v-icon @click.stop="toggleDrawer">mdi mdi-menu</v-icon>

        <div v-if="pageTitle" class="text-h5 ml-4">{{ $t(pageTitle) }}</div>

        <v-spacer></v-spacer>

        <v-menu bottom transition="slide-y-transition">
            <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon>
                    <v-avatar size="44">
                        <v-icon>mdi mdi-account-circle-outline</v-icon>
                    </v-avatar>
                </v-btn>
            </template>

            <v-list v-if="user" class="user-menu">

                <v-list-item class="label">
                    <v-list-item-avatar>
                        <v-avatar class="primary" size="40">
                            <v-icon dark>mdi mdi-account</v-icon>
                        </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <v-list-item-title>{{ user.name }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list-item @click="changePass()">

                    <v-list-item-action>
                        <v-icon class="logout-icon">mdi mdi-key</v-icon>
                    </v-list-item-action>

                    <v-list-item-content>
                        <v-list-item-title>{{$t('general.edit_user_password')}}</v-list-item-title>
                    </v-list-item-content>

                </v-list-item>

              <v-list-item @click="logout">

                <v-list-item-action>
                  <v-icon class="logout-icon">mdi mdi-logout-variant</v-icon>
                </v-list-item-action>

                <v-list-item-content>
                  <v-list-item-title>{{$t('general.logout')}}</v-list-item-title>
                </v-list-item-content>

              </v-list-item>

            </v-list>
        </v-menu>
    </v-app-bar>


    <v-dialog
        v-model="show.changePass"
        width="500"
    >
      <div  class="edit-venue">
        <div class="edit-venue-header">
          {{$t('general.edit_user_password')}}
        </div>
        <change-password-form
            class="edit-venue-content"
            :userData="$store.state.user"
            :dialog="show.changePass"
            @save="onPasswordUpdate"
        />
      </div>
    </v-dialog>



  </div>
</template>

<script>
import UserService from '@/services/UserService';
import ChangePasswordForm from "./ChangePasswordForm.vue";

export default {
  components: {ChangePasswordForm},
    created() {
        EventBus.$on('route-update', route => {
            this.pageTitle = route.title;
        });
    },

    data() {
        return {
            pageTitle: null,

          show: {
            changePass: false
          },
        };
    },

    methods: {
        /**
         * User Logout
         */
        logout() {
            ApiService.post('/logout', {});

            UserService.removeToken();

            UserService.removeUser();

            this.$router.push({ name: 'login' });
        },

        toggleDrawer() {
            EventBus.$emit('admin-toggle-drawer');
        },

      changePass() {
        this.show.changePass = true;
      },
      onPasswordUpdate(){
        this.show.changePass = false
      },
    }
};
</script>

<style scoped lang="scss">
.admin-app-bar {
    border-bottom: 1px solid #d6d8e4;

    .user-menu {
        padding: 0;
        font-size: 16px;

        .label {
            background: #f0f0f0;
        }
    }
}

.edit-venue {
  .edit-venue-header {
    padding: 16px;
    background: #363c4a;
    color: white;
  }

  .edit-venue-content {
    padding: 16px;
    background: white;
  }
}
</style>
