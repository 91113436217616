<template>
        <v-container >
            <div class="content" >
                <v-progress-circular v-if="!loadingContainer" indeterminate></v-progress-circular>
            </div>

            <!-- Basic details -->
            <div v-if="loadingContainer">
            <v-row>
                <v-card style="padding:20px; width:600px;">
                    <v-row>
                        <h3 style="text-align: left">{{ resellerDetails.name }}</h3>
                    </v-row>
                
                    <v-row>
                        <v-col :cols="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 12 : 6">
                            <v-text-field 
                                v-model="resellerDetails.tax_id_number" 
                                :label="$t('general.email')" 
                                disabled>
                            </v-text-field>
                            <v-text-field 
                                v-model="resellerDetails.email" 
                                :label="$t('general.phone')" 
                                disabled>
                            </v-text-field>
                            <v-text-field 
                                v-model="resellerDetails.phone" 
                                :label="$t('general.email')" 
                                disabled>
                            </v-text-field>
                            <v-text-field 
                                v-model="resellerDetails.address" 
                                :label="$t('general.adress')" 
                                disabled>
                            </v-text-field>
                            <v-text-field 
                                v-model="resellerDetails.contact_person" 
                                :label="$t('general.contact_person')" 
                                disabled>
                            </v-text-field>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? 12 : 6"> 
                            <v-text-field 
                                v-model="resellerDetails.email" 
                                :label="$t('general.email')" 
                                disabled>
                            </v-text-field>
                            <v-text-field 
                                v-model="resellerDetails.city" 
                                :label="$t('general.city')" 
                                disabled>
                            </v-text-field>
                            <v-text-field 
                                v-model="resellerDetails.postal_number" 
                                :label="$t('general.postal_number')" 
                                disabled>
                            </v-text-field>
                            <v-text-field 
                                v-model="resellerDetails.country" 
                                :label="$t('general.city')" 
                                disabled>
                            </v-text-field>
                            <h1 style="text-align: left"><span :style="[resellerDetails.amount_due <= 0 ? {'color': 'green'} : {'color': 'red'} ]"> {{resellerDetails.amount_due * (-1) | twoDecimals}}€ </span> </h1>
                        </v-col>
                    </v-row>
                </v-card>
            </v-row> <br>

          
                <v-row>
                    <v-col class="ma-0  pa-0" align="right">
                        <v-btn
                            outlined 
                            @click="show.paymentDialog = true"
                        >
                            {{$t('companies.payments.last_invoice')}}
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row>
                    
                    <v-row>
                        <v-col>
                            <v-card style="padding:10px">
                                <h4 style="text-align: left" class="text-overline">Invoices</h4>
                                <div>
                                    <v-data-table
                                        class="operations striped"
                                        :headers="invoiceHeaders"
                                        :items="invoices"
                                        :options.sync="invoicePagination"
                                        :server-items-length="invoiceTotal"
                                        :items-per-page.sync="invoicePagination.itemsPerPage"
                                        :footer-props="{'items-per-page-options': [10, 20, 50, 100]}"
                                        :mobile-breakpoint="0"
                                        :loading="invoiceLoading"
                                    >
                                        <template v-slot:item="{invoiceHeaders, item}">
                                            <tr :style="item.cancels_id || item.canceled_by ? 'color:red' : 'color:black'">
                                                <td>{{ item.billing_start_date | dateFromUtc | onlyDate}}</td>
                                                <td>{{ item.billing_end_date | dateFromUtc | onlyDate}}</td>
                                                <td>{{ item.amount_with_taxes | twoDecimals }}</td>   
                                                <td> <a v-if="item.url" :href="item.url" target="_blank">Invoice</a>
                                                    <span v-else>-</span>
                                                </td>
                                                <td>
                                                    <v-menu bottom transition="slide-y-transition">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn v-on="on" icon>
                                                                <v-icon >mdi mdi-dots-horizontal</v-icon>
                                                            </v-btn>
                                                        </template>

                                                        <v-list>
                                                            <template>
                                                                <v-list-item
                                                                    :key="1"
                                                                    @click="resendInvoice(item.id, 'invoice')"

                                                                >
                                                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                                                        <v-icon>mdi mdi-email</v-icon>
                                                                    </v-list-item-action>
                                                                    <v-list-item-title>
                                                                        {{$t('general.resend')}}
                                                                    </v-list-item-title>
                                                                    <v-divider />
                                                                </v-list-item>
                                                                <v-divider />
                                                            </template>
                                                        </v-list>
                                                        <v-list v-if="!item.cancels_id && !item.canceled_by"> 
                                                            <template>
                                                            <v-list-item
                                                                    :key="2"
                                                                    @click="openCancelInvoiceDialog(item)"
                                                                >
                                                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                                                        <v-icon>mdi mdi-minus-box</v-icon>
                                                                    </v-list-item-action>
                                                                    <v-list-item-title>Cancel</v-list-item-title>
                                                                    <v-divider />
                                                                </v-list-item>
                                                                <v-divider/>
                                                            </template>
                                                        </v-list>
                                                    </v-menu>
                                                </td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </div>
                            </v-card>
                        </v-col>
                        <v-col align="right">
                            <v-card style="padding:10px">
                                <h4 style="text-align: left" class="text-overline">Payments</h4>
                                <div>
                                    <v-data-table
                                        class="operations striped"
                                        :headers="paymentHeaders"
                                        :items="payments"
                                        :options.sync="paymentPagination"
                                        :server-items-length="paymentTotal"
                                        :items-per-page.sync="paymentPagination.itemsPerPage"
                                        :footer-props="{'items-per-page-options': [10, 20, 50, 100]}"
                                        :mobile-breakpoint="0"
                                        :loading="paymentLoading"
                                    >
                                        <template v-slot:item="{invoiceHeaders, item}">
                                            <tr>
                                                <td>{{ item.amount | twoDecimals}}</td>
                                                <td>{{ item.paid_at | dateFromUtc | onlyDate}}</td>
                                                <td>{{ item.reference_number }}</td>
                                                <td>{{ item.created_at | dateFromUtc }}</td>
                                            </tr>
                                        </template>
                                    </v-data-table>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-row>

                <v-dialog v-model="show.paymentDialog" width="350">   
                    <div>
                        <v-card>
                            <div class="dialog-header">
                                {{$t('companies.payments.dialog')}}
                            </div>
                            <div style="padding:4%">
                                <div style="text-align:center">
                                    <v-date-picker 
                                        v-model="payment.date"
                                        label="Date"
                                    >
                                    </v-date-picker>
                                </div>
                                <v-text-field
                                    v-model="payment.amount_with_taxes"
                                    :label="$t('general.amount')"
                                ></v-text-field>
                                <v-text-field
                                    v-model="payment.referenceNumber"
                                    :label="$t('companies.payments.reference_number')"
                                ></v-text-field>
                                <div style="text-align:center">
                                    <v-btn outlined @click="completePayment()">{{$t('general.pay')}}</v-btn>
                                </div>
                            </div>
                        </v-card>
                    </div>
                </v-dialog>

                <div>
                    <v-dialog
                        v-model="show.cancelInvoice"
                        width="400" 
                    >   
                        <div class="dialog-header">
                            {{$t('general.cancel_header')}}
                        </div>   
                        <v-card 
                            style="padding:10px;" 
                            v-click-outside="resetInvoiceCancelation"
                        >
                            <v-text-field
                                v-model="cancelationReason"
                                :label="$t('general.cancel_reason')"
                                append-icon="far fa-search"
                            />
                            <v-card-actions class="justify-center">
                                <v-btn
                                    :loading="cancelInvoiceClicked"
                                    color="blue darken-1"
                                    text
                                    @click="cancelInvoice()"
                                >
                                {{$t('general.cancel')}}
                                </v-btn>
                            </v-card-actions>
                            </v-card>
                    </v-dialog>
                </div>
            </div>
        </v-container>
</template>

<script>

import i18n from '@/i18n'

export default {
    components:{
    },
    data(){
        return {
            resellerDetails: {
                name: "",
                PIB: "",
                amount_due: ""
            },
            invoiceHeaders: [
                { text: this.$t('general.start_date'), value: "billing_start_date" },
                { text: this.$t('general.end_date'), value: "billing_end_date" },
                { text: this.$t('general.total_amount'), value: "amount_with_taxes" },
                { text: this.$t('general.link'), value: "url" },
                { text: this.$t('general.actions'), value: "" },
            ],
            paymentHeaders: [
                { text: this.$t('general.total_amount'), value: "amount_due" },
                { text: this.$t('general.paid_at'), value: "paid_at" },
                { text: this.$t('general.reference_number'), value: "reference_number" },
                { text: this.$t('general.created_at'), value: "created_at" },
            ],
            show: {
                terminateDialog: false,
                confirmDialog: false,
                paymentDialog: false,
                confirmActivationDialog: false,
                createInvoiceDialog: false,
                cancelInvoice: false
            },
            loading: false,
            invoiceLoading: false,
            paymentLoading: false,
            invoicePagination: {
                sortBy: [""],
                itemsPerPage: 10,
                page: 1,
                sortDesc: [true],
            },
            paymentPagination: {
                sortBy: [""],
                itemsPerPage: 10,
                page: 1,
                sortDesc: [true],
            },
            total: 0,
            invoiceTotal: 0,
            paymentTotal: 0,
            filter: "",
            tempTenants: [],
            invoices:[],
            payments:[],
            terminationReason: "",
            selectedContract: {
                tenant: {
                    email: ""
                },
                contract:{
                    name: ""
                }
            },
            selectedOption: "",
            selectedTenantForActivation: {},
            reseller: "",
            tempReseller: "",
            payment:{
                amount_with_taxes: 0,
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                referenceNumber: ""
            },
            createInvoiceDate: {},
            nowDate: new Date().toISOString().slice(0,10),
            resellers:[],
            invoiceForCancelation: {},
            cancelationReason: '',
            loadingContainer: false,
            tempResellerDetails: {},
            creatingInvoiceClicked: false,
            editEmail: '',
            editPhone: '',
            cancelInvoiceClicked: false
        }
    },
    created() {
        ApiService.get(`/admin/resellers/21`).then(response => { this.resellerDetails = response.data; });
        this.getInvoices();
    },
    watch: {
        invoicePagination: {
            deep: true,
            handler() {
                this.getInvoices();
            },
        },
        paymentPagination: {
            deep: true,
            handler() {
                this.getPayments();
            },
        },
    },
    methods: {
        getInvoices(){
            const { sortBy, sortDesc, page, itemsPerPage } = this.invoicePagination;

            const params = {
                page: page,
                items_per_page: itemsPerPage,
                sort_by: sortBy[0] ? sortBy[0] : "created_at",
                sort_order: sortDesc[0] ? "desc" : "asc",
            };

            ApiService.get(`/admin/resellers/${this.$route.params.id}/invoices`, { params }).then(response =>{
                this.invoices = response.data.data;
                this.invoiceTotal = response.data.total;
                this.invoiceLoading = false;
                this.loadingContainer = true;
            });

        },
        getPayments(){
            const { sortBy, sortDesc, page, itemsPerPage } = this.paymentPagination;

            const params = {
                page: page,
                items_per_page: itemsPerPage,
                sort_by: sortBy[0] ? sortBy[0] : "created_at",
                sort_order: sortDesc[0] ? "desc" : "asc",
            };

            ApiService.get(`/admin/resellers/${this.$route.params.id}/payments`, { params }).then(response =>{
                this.payments = response.data.data;
                this.paymentTotal = response.data.total;
                this.paymentLoading = false;
            });

        },
        showCreateInvoiceDialog(){
            this.show.createInvoiceDialog = true;
            this.createInvoiceDate = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
        },


        openCancelInvoiceDialog(item){
            this.show.cancelInvoice = true;
            this.invoiceForCancelation = item;
        },
        resetInvoiceCancelation(){
            this.cancelationReason = "";
        },
        async cancelInvoice(){

            const url = `/admin/companies/${this.resellerDetails.id}/invoices/${this.invoiceForCancelation.id}/cancel`;
            this.cancelInvoiceClicked = true;

            try{
                await ApiService.post(url, { cancel_comment: this.cancelationReason }).then(response => {
                    if(response.status === 200){
                        this.show.cancelInvoice = false;

                        const selectedIndex = this.invoices.findIndex(item => item.id === this.invoiceForCancelation.id);
                        this.invoices[selectedIndex] = response.data.cancelInvoices.cancelInvoice;
                        this.invoices.unshift(response.data.cancelInvoices.canceledInvoice);
                        this.resellerDetails.amount_due = response.data.amount_due
                        this.cancelInvoiceClicked = false;
                        
                        this.$snackbar('Invoices canceled', 'success');
                        this.resetInvoiceCancelation();
                        
                        return;
                    }
                });
            }
            catch(error){
                error.response.data.errors.forEach(error=> {
                    this.cancelInvoiceClicked = false;
                    this.$snackbar(error.general, 'error');
                })
            }
        },
        async completePayment(){

            const params = {
                amount: this.payment.amount_with_taxes,
                reference_number: this.payment.referenceNumber,
                paid_at: this.payment.date,
            }

            try{
                await ApiService.post(`/admin/resellers/${this.$route.params.id}/payments`, params).then(response => {
                    if(response.status === 200){
                        this.payments.unshift(response.data)
                        this.paymentTotal = this.payments.length;
                        this.$snackbar('Payment successful', 'success');
                        this.show.paymentDialog = false;
                        this.payment.referenceNumber = "";
                        this.resellerDetails.amount_due = response.data.reseller.amount_due;
                        return;
                    }
                });
            }
            catch(error){
                console.log(error);
            }
        },
        updateResellerInfo(prop){
            if(prop === 'email') this.resellerDetails.email = this.editEmail;
            if(prop === 'phone') this.resellerDetails.phone = this.editPhone;

            ApiService.patch(`/admin/companies/${this.resellerDetails.id}`, this.resellerDetails).then(response => {
                this.$snackbar(`${this.$t('general.' + prop)} ${this.$t('general.successfully_updated')}`, 'success');
            });
        },
    },
}
</script>

<style>
 .dialog-header {
    padding: 16px;
    background: #363c4a;
    color: white;
}
.table-header{
    background-color:white;
    padding-left:1%
}

.vl {
  border-left: 1px solid #363c4a;
}

.content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

</style>