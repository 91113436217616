<template>
    <div class="integrations">
        <v-row class="mb-4">
            <v-col cols="6" sm="6">
                <v-text-field
                    v-model="nameFilter"
                    :label="$t('general.search')"
                    append-icon="far fa-search"
                    clearable
                    clear-icon="mdi mdi-magnify"
                    single-line
                    hide-details
                    @input="filterIntegrations()"
                />
            </v-col>
            <v-col align="right"  class="mt-3">
                <v-btn
                    v-if="$store.state.user.role === 'admin'"
                    outlined
                    @click="show.createIntegration = true; createIncrement++"
                >
                    <v-icon>mdi mdi-plus</v-icon>
                    {{$t('general.create')}}
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            class="operations striped"
            :headers="headers"
            :items="integrations"
            :options.sync="pagination"
            :server-items-length="total"
            :footer-props="{'items-per-page-options': [20, 50, 100, 200, 500, 1000]}"
            :items-per-page.sync="pagination.itemsPerPage"
            :loading="loading"
            :mobile-breakpoint="0"
        >

            <template v-slot:item="{headers, item, index }">
                <tr>
                    <td>{{ item.name.replaceAll('_'," ") }}</td>
                    <td>{{ item.status }}</td>
                    <td>{{ item.description }}</td>
                    <td v-if="$store.state.user.role === 'admin'" class="w60" align="center">
                        <v-menu bottom transition="slide-y-transition">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon>
                                    <v-icon >mdi mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list v-if="$store.state.user.role === 'admin'">
                                <template>
                                    <v-list-item
                                        :key="2"
                                        @click="openEditIntegration(item)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-account-edit</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>{{$t('general.edit')}}</v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>

                    </td>
                </tr>
            </template>
        </v-data-table>

        <!-- Create Integration dialog -->
        <v-dialog
            v-model="show.createIntegration"
            width="500"
            :key="createIncrement"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('integrations.create')}}
                </div>
                <integration-form
                    class="dialog-content"
                    :action="'post'"
                    @save="onIntegrationUpdate"
                />
            </div>
        </v-dialog>


        <!-- Edit Integration dialog -->
        <v-dialog
            v-model="show.editIntegration"
            width="500"
            :key="editIncrement"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('integrations.edit')}}
                </div>
                <integration-form
                    class="dialog-content"
                    :integration="curIntegration"
                    :action="'patch'"
                    @save="onIntegrationUpdate"
                />
            </div>
        </v-dialog>

    </div>
</template>

<script>
import _ from "lodash";
import IntegrationForm from "@/views/admin/Integrations/IntegrationForm";

export default {
    components: {
        IntegrationForm,
    },
    data() {
        return {
            loading: false,
            details: false,
            total: 0,
            pagination: {
                sortBy: ["created_at"],
                itemsPerPage: 100,
                page: 1,
                sortDesc: [true],
            },

            headers: [
                { text: this.$t('general.name'), value: "name" },
                { text: this.$t('general.status'), value: "status" },
                { text: this.$t('general.description'), value: "description" },
                { text: "", value: "options" , sortable: false },
            ],

            nameFilter: null,
            integrations: [],
            curIntegration: null,
            show: {
                editIntegration: false,
                createIntegration: false,
            },
            createIncrement: 0,
            editIncrement: -1,
        };
    },
    watch: {
        pagination: {
            deep: true,
            handler() {
                this.getIntegrations();
            },
        },
    },
    methods: {
        /**
         * Get Paginated, Filtered and Sorted transactions from server
         */
        getIntegrations() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : "created_at",
                sortOrder: sortDesc[0] ? "desc" : "asc",
            };

            if (this.nameFilter) params["name"] = this.nameFilter;

            this.loading = true;
            ApiService.get("/admin/integrations", { params: params }).then(
                response => {
                    this.loading = false;
                    this.integrations = response.data.integrations.data
                    this.total = response.data.total;

                }
            );
        },
        onIntegrationUpdate() {
            this.editIncrement--;
            this.show.editIntegration = false;
            this.show.createIntegration = false;
            this.curIntegration = null;
            this.getIntegrations();
        },
        openEditIntegration(item) {
            this.curIntegration = item;
            this.show.editIntegration = true;
        },

        /**
         * Filter Integrations
         */
        filterIntegrations: _.debounce(function () {
            this.getIntegrations();
        }, 500),
    },
};
</script>

<style lang="scss" scoped>
.integrations {
    padding: 20px;
    background: white;
    border-radius: 4px;
    border: 1px solid #d3d5dc;
}

.dialog {
    .dialog-header {
        padding: 16px;
        background: #363c4a;
        color: white;
    }

    .dialog-content {
        padding: 16px;
        background: white;
    }
}
.sum-td{
    font-weight:bold;
    font-size: 20px !important;
}
</style>
