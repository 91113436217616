<template>
    <div class="resellers">
        <v-row class="mb-4">
            <v-col cols="6" sm="6">
                <v-text-field
                    v-model="filter"
                    :label="$t('general.search')"
                    append-icon="far fa-search"
                    clearable
                    clear-icon="mdi mdi-magnify"
                    single-line
                    hide-details
                    @input="filterResellers()"
                />
            </v-col>
            <v-col align="right">

                <v-btn v-if="canEdit"
                    outlined 

                    @click="exportContracts()"
                    >
                    <v-icon>mdi mdi-application-export</v-icon>
                    {{$t('general.export_contracts')}}
                </v-btn>
                 <v-btn
                     v-if="canEdit"
                    outlined
                    @click="show.createReseller = true; createIncrement++"
                >
                    <v-icon>mdi mdi-plus</v-icon>
                    {{$t('general.create')}}
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            class="operations striped"
            :headers="headers"
            :items="resellers"
            :options.sync="pagination"
            :server-items-length="total"
            :footer-props="{'items-per-page-options': [20, 50, 100, 200, 500, 1000]}"
            :items-per-page.sync="pagination.itemsPerPage"
            :loading="loading"
            :mobile-breakpoint="0"
        >

            <template v-slot:item="{headers, item, index }">
                <tr>
                    <td>{{ item.name }}</td>
                    <td>{{ item.hash }}</td>
                    <td>{{ item.tax_id_number }}</td>
                    <td>{{ item.email }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.address }}</td>
                    <td>{{ item.city }}</td>
                    <td>{{ item.postal_number }}</td>
                    <td>{{ item.registration_number }}</td>
                    <td>{{ item.country }}</td>
                    <td v-if="item.parent">{{ item.parent.name }}</td>
                    <td v-else></td>
                    <td>{{ item.contact_person }}</td>
                    <td>{{ item.tier }}</td>
                    <td class="w60" align="center">
                        <v-menu bottom transition="slide-y-transition">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon>
                                    <v-icon >mdi mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <template>
                                    <v-list-item
                                        :key="2"
                                        @click="openEditReseller(item)"
                                    >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                        <v-icon>mdi mdi-account-edit</v-icon>
                                            </v-list-item-action>
                                        <v-list-item-title>{{$t('general.edit')}}</v-list-item-title>
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item v-if="$store.state.user.role === 'admin' || $store.state.user.role === 'main_reseller'"
                                        :key="3"
                                        @click="openUserCreateConfirmFromReseller(item)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-account-plus</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>{{$t('general.create')}}</v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>

                    </td>
                </tr>
            </template>
        </v-data-table>

        <!-- Create reseller dialog -->
        <v-dialog
            v-model="show.createReseller"
            width="500"
            :key="createIncrement"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('resellers.create')}}
                </div>
                <reseller-form
                    class="dialog-content"
                    :action="'post'"
                    @save="onResellerUpdate"
                />
            </div>
        </v-dialog>


        <!-- Edit reseller dialog -->
        <v-dialog
            v-model="show.editReseller"
            width="500"
            :key="editIncrement"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('resellers.edit')}}
                </div>
                <reseller-form
                    class="dialog-content"
                    :reseller="curReseller"
                    :action="'patch'"
                    @save="onResellerUpdate"
                />
            </div>
        </v-dialog>

        <!-- Create user from reseller dialog -->
        <v-dialog
            v-model="show.createUserFromReseller"
            width="500"
            :key="createUserFromResellerIncrement"
        >
                <div class="dialog-header">
                    {{$t('resellers.create_user_from_reseller')}}
                </div>
            <v-card
                style="padding:10px;"
            >
                <v-card-text class="justify-center">
                    {{$t('resellers.create_user_from_reseller_text')}}
                </v-card-text>
                <v-card-actions class="justify-center">

                    <v-btn
                        :loading="createUserFromResellerClicked"
                        color="green darken-1"
                        text
                        @click="createUserFromReseller()"
                    >
                        {{$t('general.create')}}
                    </v-btn>
                    <v-btn
                        color="blue darken-1"
                        text
                        @click="show.createUserFromReseller = false">
                        {{$t('general.close')}}
                    </v-btn>

                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Select reseller for export -->
        <v-dialog
            v-model="show.selectReseller"
            width="500"
            :key="selectResellerIncrement"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('resellers.choose_reseller_for_export')}}
                </div>
                <v-card style="padding:20px;">
                    <v-autocomplete
                       v-if="$store.state.user.role === 'admin'"
                       :error-messages="autoCompleteValidation  ? 'The field is required': null"
                       v-model="creationReseller"
                       :label="$t('general.reseller')"
                       :items="autoCompleteResellers"
                       item-text="state"
                       item-value="abbr"
                       persistent-hint
                       return-object
                       single-line
                       clearable
                       @change="resellerRequired = false"
                    >
                    </v-autocomplete>
                    <div class="d-flex justify-center mb-3">
                        <v-btn
                            :disabled="!creationReseller"
                            color="primary"
                            outlined
                            @click="exportContracts()"
                        >
                            {{$t('general.export_contracts')}}
                        </v-btn>
                    </div>
                </v-card>
                </div>
        </v-dialog>
    </div>
</template>

<script>
import _ from "lodash";
import ResellerForm from "@/views/admin/Resellers/ResellerForm";

export default {
    components: {
        ResellerForm,
    },
    data() {
        return {
            loading: false,
            details: false,
            total: 0,
            pagination: {
                sortBy: ["created_at"],
                itemsPerPage: 100,
                page: 1,
                sortDesc: [true],
            },

            headers: [
                { text: this.$t('general.name'), value: "name" },
                { text: this.$t('general.hash'), value: "hash" },
                { text: this.$t('general.tax_id_number'), value: "tax_id_number" },
                { text: this.$t('general.email'), value: "email" },
                { text: this.$t('general.phone'), value: "phone" },
                { text: this.$t('general.adress'), value: "adress" },
                { text: this.$t('general.city'), value: "city" },
                { text: this.$t('general.postal_number'), value: "postal_number" },
                { text: this.$t('general.registration_number'), value: "registration_number" },
                { text: this.$t('general.country'), value: "country" },
                { text: this.$t('general.parent_reseller'), value: "parent.name" },
                { text: this.$t('general.contact_person'), value: "contact_person" },
                { text: this.$t('general.tier'), value: "tier" },
                { text: "", value: "options" },
            ],

            filter: null,
            resellers: [],
            curReseller: null,
            show: {
                editReseller: false,
                createReseller: false,
                selectReseller: false,
                createUserFromReseller: false
            },
            createIncrement: 0,
            editIncrement: -1,
            selectResellerIncrement: Math.floor(Math.random() * 1000),
            createUserFromResellerIncrement: Date.now(),
            autoCompleteValidation: false,
            autoCompleteResellers: [],
            creationReseller: null,
            createUserFromResellerClicked: false
        };
    },
    computed: {
        role() { return this.$store.state.user.role },
        canEdit() {
            return this.role=== 'main_reseller' || this.role === 'admin';
        }
    },

    watch: {
        pagination: {
            deep: true,
            handler() {
                this.getResellers();
            },
        },
    },
    methods: {
        /**
         * Get Paginated, Filtered and Sorted transactions from server
         */
        getResellers() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : "created_at",
                sortOrder: sortDesc[0] ? "desc" : "asc",
            };

            if (this.filter) params["filter"] = this.filter;
            if (this.reseller) params["reseller"] = this.reseller;

            this.loading = true;
            ApiService.get("/admin/resellers", { params: params }).then(
                response => {

                    this.loading = false;
                    this.resellers = response.data.resellers.map((reseller) => {
                        return reseller;
                    });

                    this.autoCompleteResellers = this.resellers.map(res => res.name);


                    this.total = response.data.total;

                }
            );
        },
        async createUserFromReseller(){

            //curReseller
            //console.log(this.curReseller);

            const url = `/admin/resellers/${this.curReseller.id}/user/create`;
            this.createUserFromResellerClicked = true;

            try{
                await ApiService.post(url, {email: this.curReseller.email}).then(response => {
                    if(response.status === 200){
                        this.show.cancelInvoice = false;
                        this.createUserFromResellerClicked = false;

                        this.$snackbar('User created', 'success');
                        //move this down
                        this.onCreateUserFromReseller();

                        return;
                    }
                });
            }
            catch(error){
                    this.createUserFromResellerClicked = false;
                    this.$snackbar(error.response.data.message, 'error');

            }
        },
        openEditReseller(item) {
            this.curReseller = item;
            this.show.editReseller = true;
        },
        onResellerUpdate() {
            this.editIncrement--;
            this.show.editReseller = false;
            this.show.createReseller = false;
            this.curReseller = null;
            this.getResellers();
        },
        showDetails(item){
            EventBus.$emit('route-update', { title: '' });
            this.$store.state.resellerDetails = item;
            this.$router.push({ name: "resellerDetails", params:{ id:item.id } })
        },
        async exportContracts() {

            if (this.$store.state.user.role === 'main_reseller') this.exportApi();

            if (this.$store.state.user.role === 'admin' && this.show.selectReseller) {
                const resellerId = this.resellers.filter(res => res.name === this.creationReseller)[0].id;
                this.exportApi(resellerId);
            }
            else if(this.$store.state.user.role === 'admin') {
                this.show.selectReseller = true;
            }

        },

        exportApi(reseller_id = null) {
            ApiService.get(`/admin/resellers/tenant-contracts`, {
                responseType: 'blob',
                timeout: 120000,
                params: { reseller_id },
            })
            .then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                const fileName = this.creationReseller ? this.creationReseller : this.$store.state.user.name + ' preprodavci '

                link.setAttribute('download', this.$t(fileName + ' ugovori') + '.xlsx');
                document.body.appendChild(link);
                link.click();
                this.show.selectReseller = false;
            })
            .catch(async error => {
                let responseObj = await error.response.data.text();
                const errors = JSON.parse(responseObj)?.errors
                if(errors && errors.length) {
                    this.$snackbar(this.$t(`exporting_orders.errors.${errors[0]}`), 'error');
                }
            });
        },
        openUserCreateConfirmFromReseller(item) {
            this.curReseller = item;
            this.show.createUserFromReseller = true;
        },
        onCreateUserFromReseller() {
            this.createUserFromResellerIncrement = Date.now();
            this.show.createUserFromReseller = false;
            this.curReseller = null;
            this.getResellers();
        },
        /**
         * Filter resellers
         */
        filterResellers: _.debounce(function () {
            this.getResellers();
        }, 500),
    },
};
</script>

<style lang="scss" scoped>
.resellers {
    padding: 20px;
    background: white;
    border-radius: 4px;
    border: 1px solid #d3d5dc;
}

.dialog {
    .dialog-header {
        padding: 16px;
        background: #363c4a;
        color: white;
    }

    .dialog-content {
        padding: 16px;
        background: white;
    }
}
.sum-td{
    font-weight:bold;
    font-size: 20px !important;
}
</style>
