<template>
    <div class="contracts">
        <v-row class="mb-4">
            <v-col cols="6" sm="6">
                <v-text-field
                    v-model="name_filter"
                    :label="$t('general.searchByName')"
                    append-icon="far fa-search"
                    clearable
                    clear-icon="mdi mdi-magnify"
                    single-line
                    hide-details
                    @input="filterContracts()"
                />
            </v-col>
            <v-col align="right">
                <v-btn
                    v-if="$store.state.user.role === 'admin'"
                    outlined
                    @click="show.createContract = true;"
                >
                    <v-icon>mdi mdi-plus</v-icon>
                    {{$t('general.create')}}
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            class="operations striped"
            :headers="headers"
            :items="contracts"
            :options.sync="pagination"
            :server-items-length="total"
            :footer-props="{'items-per-page-options': [20, 50, 100, 200, 500, 1000]}"
            :items-per-page.sync="pagination.itemsPerPage"
            :loading="loading"
            :mobile-breakpoint="0"
        >

            <template v-slot:item="{headers, item, index }">
                <tr>
                    <td>{{ item.name }}</td>
                    <td>{{ item.app }}</td>
                    <td>{{ item.months_duration }}</td>
                    <td>{{ item.price }}</td>
                    <td>{{ item.payment_type }}</td>
                    <td>{{ item.status }}</td>
                    <td>{{ item.number_of_allowed_modules }}</td>
                    <td>{{ item.reseller.name }}</td>
                    <td class="w60" align="center">
                        <v-menu bottom transition="slide-y-transition">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon>
                                    <v-icon >mdi mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <template>
                                    <v-list-item
                                        :key="1"
                                        @click="showDetails(item)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-eye-outline</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>{{$t('general.details')}}</v-list-item-title>
                                    </v-list-item>
                                </template>
                                <template v-if="item.status == 'disabled' && $store.state.user.role === 'admin'">
                                    <v-divider></v-divider>
                                    <v-list-item
                                        :key="2"
                                        @click="openActivateDialog(item.id)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-power</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>{{$t('general.activate')}}</v-list-item-title>
                                    </v-list-item>
                                </template>
                                <template v-if="item.status == 'active' && $store.state.user.role === 'admin'">
                                    <v-divider></v-divider>
                                    <v-list-item
                                        :key="3"
                                        @click="openDisableDialog(item.id)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-close-circle</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>{{$t('general.terminate')}}</v-list-item-title>
                                    </v-list-item>
                                </template>
                            </v-list>
                        </v-menu>

                    </td>
                </tr>
            </template>
        </v-data-table>

        <!-- Create Contract dialog -->
        <v-dialog
            v-model="show.createContract"
            width="500"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('general.create')}}
                </div>
                <ContractForm
                    @save="onContractCreate"
                />
        </div>
        </v-dialog>

        <!-- Activate Contract dialog -->
        <v-dialog
            v-model="show.activateDialog"
            width="500"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('general.warning')}}
                </div>
                <div class="dialog-content">
                <v-card-text>
                    {{$t('contracts.warningActivateMessage')}}
                </v-card-text>
                <div class="d-flex justify-space-between">
                    <v-btn
                        class="mt-7 align-center"
                        width="200px"
                        color="red"
                        outlined
                        @click="show.activateDialog = false"
                    >
                        {{ this.$t('notifications.cancel') }}
                    </v-btn>
                    <v-btn
                        class="mt-7 align-center"
                        width="200px"
                        color="primary"
                        outlined
                        @click="activate"
                    >
                        {{ this.$t('general.activate') }}
                    </v-btn>
                </div>
                </div>
            </div>
        </v-dialog>

        <!-- Disable Contract dialog -->
        <v-dialog
            v-model="show.disableDialog"
            width="500"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('general.warning')}}
                </div>
                <div class="dialog-content">
                    <v-card-text>
                        {{$t('contracts.warningDisableMessage')}}
                    </v-card-text>
                    <div class="d-flex justify-space-between">
                        <v-btn
                            class="mt-7 align-center"
                            width="200px"
                            color="red"
                            outlined
                            @click="show.disableDialog = false"
                        >
                            {{ this.$t('notifications.cancel') }}
                        </v-btn>
                        <v-btn
                            class="mt-7 align-center"
                            width="200px"
                            color="primary"
                            outlined
                            @click="disable"
                        >
                            {{ this.$t('general.terminate') }}
                        </v-btn>
                    </div>
                </div>
            </div>
        </v-dialog>

        <v-dialog
            v-model="show.details"
            width="500"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('general.details')}}
                </div>
                <div class="dialogText">
                        <v-card-text>
                            <strong>Reseller:</strong> {{ reseller }}
                        </v-card-text>
                        <v-card-text>
                            <strong>{{ $t('general.name') }}:</strong> {{ details.name }}
                        </v-card-text>

                        <v-card-text>
                            <strong>{{ $t('contracts.months_duration') }}:</strong> {{ details.months_duration }}
                        </v-card-text>

                        <v-card-text>
                            <strong> {{ $t('contracts.app') }} :</strong> {{ details.months_duration }}
                        </v-card-text>
                        <v-card-text>
                            <strong>{{ $t('contracts.price') }}:</strong> {{ details.price }}
                        </v-card-text>
                        <v-card-text>
                            <strong>{{ $t('contracts.payment_type') }}:</strong> {{ details.payment_type }}
                        </v-card-text>
                        <v-card-text>
                            <strong>{{ $t('contracts.status') }}:</strong> {{ details.status }}
                        </v-card-text>
                        <v-card-text>
                            <strong>{{ $t('contracts.number_of_allowed_modules') }}:</strong> {{ details.number_of_allowed_modules }}
                        </v-card-text>
                        <v-card-text>
                            <strong>{{ $t('general.description') }}:</strong> {{ details.description }}
                        </v-card-text>
                        <v-card-text>
                          <strong>Partner C:</strong> {{ details.contract_config ? details.contract_config.partner_c ? details.contract_config.partner_c : 'N/A' : 'N/A' }}
                        </v-card-text>
                        <v-card-text>
                          <strong>Partner B:</strong> {{ details.contract_config ? details.contract_config.partner_b ? details.contract_config.partner_b : 'N/A' : 'N/A' }}
                        </v-card-text>
                        <v-card-text>
                          <strong>Partner A:</strong> {{ details.contract_config ? details.contract_config.partner_a ? details.contract_config.partner_a : 'N/A' :  'N/A' }}
                        </v-card-text>
                        <v-card-text>
                          <strong>Partner A0:</strong> {{ details.contract_config ? details.contract_config.partner_a0 ? details.contract_config.partner_a0 : 'N/A' : 'N/A' }}
                        </v-card-text>
                </div>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import _ from "lodash";
import ContractForm from "@/views/admin/Contracts/ContractForm.vue";

export default {
    components: {
        ContractForm,
    },
    data() {
        return {
            details:{},
            reseller:{},
            loading: false,
            total: 0,
            pagination: {
                sortBy: ["created_at"],
                itemsPerPage: 100,
                page: 1,
                sortDesc: [true],
            },

            headers: [
                { text: this.$t('general.name'), value: "name" , sortable: true },
                { text: this.$t('contracts.app'), value: "app",  sortable: true },
                { text: this.$t('contracts.months_duration'), value: "months_duration",  sortable: true },
                { text: this.$t('contracts.price'), value: "price",  sortable: true },
                { text: this.$t('contracts.payment_type'), value: "payment_type",  sortable: true },
                { text: this.$t('contracts.status'), value: "status",  sortable: true },
                { text: this.$t('contracts.number_of_allowed_modules'), value: "number_of_allowed_modules",  sortable: true },
                { text: this.$t('general.reseller'), value: "reseller.name",  sortable: false },
                { text: this.$t('general.action'), value: "Action",  sortable: false },
            ],

            name_filter: null,
            contracts: [],
            currentId: null,
            show: {
                createContract: false,
                details: false,
                activateDialog: false,
                disableDialog: false,
            },
        };
    },

    watch: {
        pagination: {
            deep: true,
            handler() {
                this.getContracts();
            },
        },
    },
    methods: {

        /**
         * Get Paginated, Filtered and Sorted transactions from server
         */
        getContracts() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : "created_at",
                sortOrder: sortDesc[0] ? "desc" : "asc",
            };

            if (this.name_filter) params["filter"] = this.name_filter;
            this.loading = true;

            ApiService.get("/admin/contracts", { params: params }).then(
                response => {
                    this.loading = false;
                    this.contracts = response.data
                    this.total = this.contracts.length;
                }
            );
        },

        openActivateDialog(id){
            this.currentId = id;
            this.show.activateDialog = true
        },
        openDisableDialog(id){
            this.currentId = id;
            this.show.disableDialog = true
        },
        /**
         * Disable contract for given id
         */
        disable(){
            ApiService.post(`admin/contracts/${this.currentId}/disable`).then(
                response => {
                    this.show.disableDialog = false;
                    this.$snackbar('Contract disabled', 'success');
                    this.getContracts()
                }
            );
        },

        /**
         * Activate contract for given id
         */
        activate(){
            ApiService.post(`admin/contracts/${this.currentId}/activate`).then(
                response => {
                    this.show.activateDialog = false;
                    this.$snackbar('Contract acivated', 'success');
                    this.getContracts()
                }
            );
        },

        /**
         * Show details of Contracts
         */
        showDetails(details){
            this.details = details
            this.reseller = details.reseller.name
            this.show.details = true;
        },
        /**
         * Filter Contracts
         */
        filterContracts: _.debounce(function () {
            this.getContracts();
        }, 500),

        onContractCreate() {
            this.show.createContract = false;
            this.getContracts();
        },
    },
};
</script>

<style lang="scss" scoped>
.contracts {
    padding: 20px;
    background: white;
    border-radius: 4px;
    border: 1px solid #d3d5dc;
}
.dialogText{
    background: white;
    padding: 20px;
}
.dialog {
    .dialog-header {
        padding: 16px;
        background: #363c4a;
        color: white;
    }

    .dialog-content {
        padding: 16px;
        background: white;
    }
}
.sum-td{
    font-weight:bold;
    font-size: 20px !important;
}
</style>
