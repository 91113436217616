<template >
    <div v-click-outside="resetDialog">
        <div class="dialog-header">
            <span v-if="selectedOption === 'addContract'"> {{$t('general.add_contracts')}} - <b>{{selectedItem.email}} </b>  </span>
            <span v-else> {{$t('general.change_package')}} - <b>{{selectedItem.tenant.email}} </b>  </span>
        </div>

        <v-list class="justify-center">
            <v-text-field
                :label="$t('general.search')"
                v-model="nameFilter"
                class="ma-3"
                @input="search"
            >
            </v-text-field>
            <div v-for="(contract, index) in contracts" :key="contract.id" mb-4>
                <v-list-item >
                    <v-list-item-action>
                        <v-checkbox
                            v-model="contract.checked"
                            @change="changeSelectedApp($event, contract.app, index)"
                            v-if="isAvailable(contract.app)"
                        ></v-checkbox>
                    </v-list-item-action>
                    <v-row>
                        <v-list-item-content>
                            <v-list-item-title>{{contract.name}}</v-list-item-title>
                            <v-list-item-subtitle>{{contract.price}} €</v-list-item-subtitle>
                            <v-list-item-subtitle>{{contract.months_duration}} months</v-list-item-subtitle>
                            <v-list-item-subtitle></v-list-item-subtitle>
                        </v-list-item-content>


                        <!-- Date picker  -->
                        <v-menu
                            v-if="contract.checked"
                            v-model="menu2[index]"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="contract.date"
                                label="Datum ugovora"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                                class="shrink"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="contract.date"
                                @input="menu2[index] = false"
                                :type="$i18n.locale === 'sr' ? 'month' : 'date'"
                            ></v-date-picker>
                        </v-menu>
                        <!-- Date picker  -->

                        <v-list-item-action style="max-width: 20%;">
                            <v-text-field
                                v-if="contract.checked"
                                flat solo
                                style="height:35px"
                                v-model="contract.quantity"
                                type="number" label="Number" append-outer-icon="add"
                                @click:append-outer="contract.quantity = contract.quantity + 1"
                                prepend-icon="remove"
                                @click:prepend="contract.quantity = contract.quantity - 1"
                            />
                        </v-list-item-action>

                    </v-row>
                </v-list-item>
                <v-divider></v-divider>
            </div>
            <v-card-actions class="justify-center">
                <v-btn outlined color="blue" @click="saveContracts(selectedItem.id)">{{$t('general.save')}}</v-btn>
            </v-card-actions>

        </v-list>
    </div>
</template>

<script>
import i18n from '@/i18n'

export default {
    name: "ContractForm",
    props:[
        "contracts",
        "selectedItem",
        "selectedOption"
    ],
    data(){
        return {
            selectedApp: "",
            deselectedAll: true,
            checkBoxes: [],
            date: [],
            menu: false,
            modal: false,
            menu2: [false],
            nameFilter: null
        }
    },
    mounted(){
        this.checkBoxes = new Set();
    },
    methods:{
        async saveContracts(item_id){


            const contracts = {
                "contracts": this.contracts.filter(contract => contract.checked)
                .map(function (contract){
                    return {
                        id: contract.id,
                        quantity: contract.quantity,
                        start_date: contract.date ? contract.date :
                            i18n.locale === 'me' ?
                                (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)
                            :   (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 8) + '01'
                    };
                })
            }


            const url = this.selectedOption === 'addContract' ? `/admin/tenants/${item_id}/add-contract`
                                                              : `/admin/tenant-contracts/${item_id}/contracts`;

            try{
                await ApiService.post(url, contracts).then(response => {
                    if(response.status === 200){
                        this.selectedApp = "";
                        this.deselectedAll = true;

                        if (this.selectedOption === 'addContract') {
                            this.$emit("closeDialog", { contracts: response.data })
                            this.$snackbar(this.$t('general.contracts_added'), 'success');
                        }

                        if (this.selectedOption === 'updateContract') {
                            this.$emit("closeDialogChangePackage", {
                                contracts: response.data.contracts,
                                terminatedContracts: response.data.terminated,
                            })
                            this.$snackbar(this.$t('general.contracts_updated'), 'success');
                        }

                        return;
                    }
                });

            }
            catch(error){
              if (error.response.status === 403) {
                this.$snackbar('You are not authorized to perform this action.', 'error');
              }
              else if(error.response.status === 422){
                error.response.data.errors.contracts.forEach(error=> this.$snackbar(error, 'error'))
              }
              else {
                this.$snackbar(error.response.data.message, 'error')
              }
            }

        },
        changeSelectedApp(event, app, index){

            this.checkBoxes.add(index);

            if(this.checkBoxes.has(index) && !event){
                this.checkBoxes.delete(index);
            }

            if(this.checkBoxes.size === 0){
                this.selectedApp = "";
                this.deselectedAll = false;
                return;
            }

            this.selectedApp = app;
            this.deselectedAll = false;
        },
        isAvailable(app){
            return this.selectedApp ?  this.selectedApp == app :  true;
        },
        resetDialog(){
            this.selectedApp = "";
            this.deselectedAll = true;
            this.checkBoxes.clear()
        },
        search(){
            this.$emit("search",{nameFilter: this.nameFilter});
        }

    }


}
</script>

<style>

</style>
