<template>
  <v-form
      v-if="form"
      ref="form"
      class="venue-form"
  >
    <v-text-field
        v-model="form.name"
        :error-messages="form.$errors.name"
        :label="$t('general.name')"
        required
    />

    <v-text-field
        v-model="form.email"
        :error-messages="form.$errors.email"
        :label="$t('general.email')"
        autocomplete="off"
        required
    />

    <v-select
        :items="['admin', 'main_reseller', 'reseller']"
        :error-messages="form.$errors.role"
        v-model="form.role"
        :label="$t('general.role')"
    >
    </v-select>

    <v-select
        :items="resellers"
        :error-messages="form.$errors.reseller_id"
        v-model="form.reseller_id"
        :label="$t('general.reseller')"
        item-text="name"
        item-value="id"
    >
    </v-select>

    <v-text-field
        v-model="form.password"
        :error-messages="form.$errors.password"
        :label="$t('general.password')"
        :append-icon="showPassword ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"
        :type="showPassword ? 'text' : 'password'"
        autocomplete="off"
        @click:append="showPassword = !showPassword"
    />

    <v-text-field
        v-model="form.password_confirmation"
        :error-messages="form.$errors.passwordConfirmation"
        :label="$t('general.confirm_password')"
        :append-icon="showPasswordConfirmation ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"
        :type="showPasswordConfirmation ? 'text' : 'password'"
        @click:append="showPasswordConfirmation = !showPasswordConfirmation"
    />

    <div class="d-flex justify-center mb-3">
      <v-btn
          color="primary"
          outlined
          @click="save"
      >
        {{$t('general.save')}}
      </v-btn>
    </div>

  </v-form>
</template>

<script>
import ApiService from '@/services/ApiService'
import * as rules from 'vuelidate/lib/validators'
import { minLength, sameAs }  from 'vuelidate/lib/validators'

export default {
  props: {
    tenant: {
      type: Object,
      default: () => null
    }
  },

  created() {
    this.form = this.createForm()
  },

  data() {
    return {
      form: null,
      showPassword: false,
      showPasswordConfirmation: false,
      showPin: false,
      showPinConfirmation: false,
      resellers:[]
    };
  },

  validations: {
    form: {
      name: { required: rules.required },
      email: { required: rules.required, email: rules.email },
      role: { required: rules.required },
      reseller_id: { required: rules.required },
      password: { minLength: minLength(8) },
      passwordConfirmation: {
        sameAs: sameAs(function () { return this.password })
      },
    }
  },


  computed: {
    isFiscal() {
      return locale === 'me'
    },
  },

  methods: {
    createForm(tenant = null) {
      //Populate resellers array
      this.getResellers();

      return new CodForm(this, 'form', {
        id: tenant ? tenant.id : null,
        name: tenant ? tenant.name : null,
        email: tenant ? tenant.email : null,
        password: null,
        password_confirmation: null,
        reseller_id: tenant ? tenant.reseller : null,
        role: null,
      })

    },
    async getResellers(){
      const response = await ApiService.get("/admin/resellers");
      this.resellers = response.data.resellers
    },
    /**
     * Save Venue
     */
    save() {
      if (!this.form.validate()) return
      ApiService.post(`/admin/users`, this.form).then(response => {
        this.$emit('save', response.data.tenant)
        this.$snackbar('User created', 'success');
        this.form = this.createForm()
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.date-picker{
  margin: 0 auto;
}
</style>