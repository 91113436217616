<template>
  <div class="stat-container">
      <div class="stat-circle" :style="{ backgroundColor : backgroundColor }"></div>
      <div class="stat-icon">
        <v-icon class="ml-3 mr-4" color="black" size="40">{{ icon }}</v-icon>
      </div>
      <div>
          <div class="stat-value" :class="{'positive': parseFloat(value) <= 0 && currency !== ''}" >{{ value }} {{ariaAlegra}} {{currency}}</div>
          <div class="stat-label">{{ label }}</div>
      </div>
  </div>
</template>



<script>
export default {
  props: {
    value: {
      required: true,
      validator: (propValue) => {
        // Custom validation logic
        return typeof propValue === 'string' || typeof propValue === 'number';
      }
    },
    currency: {
      type: String,
      default: ''
    },
    aria: {
      validator: (propValue) => {
        // Custom validation logic
        return typeof propValue === 'string' || typeof propValue === 'number';
      },
      default: ''
    },
    allegra: {
      validator: (propValue) => {
        // Custom validation logic
        return typeof propValue === 'string' || typeof propValue === 'number';
      },
      default: ''
    },
    label: {
      type: String,
      required: true
    },
    icon:{
      type: String,
      required: false
    },
    backgroundColor: {
      type: String,
      required: false
    }
  },

  computed: {
    ariaAlegra() {
      return ((this.aria && this.allegra) || (this.aria === 0 || this.allegra === 0)) ? `(${this.aria}/${this.allegra})` : '';
    },

  },
}
</script>

<style scoped>
.stat-container {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: white;
}
.stat-circle{
  width: 10px ;
  height: 10px;
  border-radius: 50%;
}
.stat-icon{
    width: 20%;
    display: flex;
    align-items: center;
}
.stat-value {
  font-size: 24px;
  font-weight: bold;
}

.stat-label {
  font-size: 14px;
  color: #888;
  margin-top: 8px;
}
.positive {
  color: rgba(71, 183,132,.5);
}

</style>
