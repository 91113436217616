<template>
    <v-dialog v-model="show" :max-width="width" :persistent="persistent" @keydown.esc="choose(null)">
        <v-toolbar v-if="Boolean(title)" :color="color" dark dense>
            <v-btn icon dark v-if="Boolean(icon)" @click="choose(false)">
                <v-icon class="icon mr0">
                    {{ icon }}
                </v-icon>
            </v-btn>
            <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
        </v-toolbar>

        <v-card tile>
            <v-card-text class="message">{{ message }}</v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="Boolean(buttonCancelText)"
                    :color="buttonCancelColor"
                    text
                    @click="choose(null)"
                >
                    {{ buttonCancelText }}
                </v-btn>
                <v-spacer />
                <v-btn
                    v-if="Boolean(buttonFalseText)"
                    :color="buttonFalseColor"
                    text
                    @click="choose(false)"
                >
                    {{ buttonFalseText }}
                </v-btn>
                <v-btn
                    v-if="Boolean(buttonTrueText)"
                    :color="buttonTrueColor"
                    text
                    @click="choose(true)"
                >
                    {{ buttonTrueText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    created() {
        EventBus.$on('show-confirm', (message, options = {}, callback) => {
            this.showConfirm(message, options, callback);
        });
    },

    data() {
        return {
            show: false,
            buttonCancelText: 'Cancel',
            buttonTrueText: 'Yes',
            buttonFalseText: 'No',
            buttonCancelColor: 'grey',
            buttonTrueColor: 'primary',
            buttonFalseColor: 'grey',
            color: 'warning',
            icon: 'information-outline',
            message: null,
            persistent: false,
            title: null,
            width: 350,
            callback: () => {}
        };
    },

    methods: {
        showConfirm(message, options = {}, callback) {
            let delay = this.show ? 500 : 0;
            this.show = false;

            setTimeout(() => {
                this.buttonTrueText = options.buttonTrueText || 'Yes';
                this.buttonFalseText = options.buttonFalseText || null;
                this.buttonCancelText = options.buttonCancelText || 'Cancel';
                this.buttonTrueColor = options.buttonTrueColor || 'primary';
                this.buttonFalseColor = options.buttonFalseColor || 'grey';
                this.buttonCancelColor = options.buttonCancelColor || 'grey';
                this.color = options.color || 'warning';
                this.icon = options.icon || 'information-outline';
                this.message = message;
                this.persistent = options.persistent || false;
                this.title = options.title;
                this.width = options.width || 350;
                this.callback = callback
                    ? result => {
                          callback(result);
                          this.show = false;
                      }
                    : result => (this.show = false);

                this.show = true;
            }, delay);
        },

        choose(value) {
            this.$emit('result', value);
            this.callback(value);
        }
    }
};
</script>

<style lang="scss" scoped>
.icon {
    margin-right: 10px;
}

.message {
    font-size: 16px;
    padding: 15px !important;
}
</style>
