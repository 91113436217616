<template>
    <v-form
        v-if="form"
        ref="form"
        class="form"
    >
        <v-text-field
            v-model="form.name"
            :error-messages="form.$errors.name"
            :label="$t('general.name')"
            required
        />


        <v-select
            :items="['aria', 'allegra']"
            v-model="form.app"
            :error-messages="form.$errors.app"
            :label="$t('general.app')"
        >
        </v-select>

        <v-select
            :items="resellers"
            :error-messages="form.$errors.reseller_id"
            v-model="form.reseller_id"
            :label="$t('general.reseller')"
            item-text="name"
            item-value="id"
        >
        </v-select>

        <v-text-field
            v-model="form.months_duration"
            :error-messages="form.$errors.months_duration"
            :label="$t('contracts.months_duration')"
            required
        />

        <v-text-field
            v-model="form.price"
            :error-messages="form.$errors.price"
            :label="$t('contracts.price')"
            required
        />

        <v-select
            :items="['monthly', 'yearly', 'in-full']"
            v-model="form.payment_type"
            :error-messages="form.$errors.payment_type"
            :label="$t('contracts.payment_type')"
        >
        </v-select>

        <v-select
            :items="['active', 'disabled']"
            v-model="form.status"
            :error-messages="form.$errors.status"
            :label="$t('contracts.status')"
        >
        </v-select>

        <v-text-field
            v-model="form.number_of_allowed_modules"
            :error-messages="form.$errors.number_of_allowed_modules"
            :label="$t('contracts.number_of_allowed_modules')"
            required
        />

        <v-text-field
            v-model="form.description"
            :label="$t('contracts.description')"
            required
        />

        <v-text-field
            v-model="form.partner_c"
            :error-messages="form.$errors.partner_c"
            label="Partner C"
        />

        <v-text-field
            v-model="form.partner_b"
            :error-messages="form.$errors.partner_b"
            label="Partner B"
            required
        />

        <v-text-field
            v-model="form.partner_a"
            :error-messages="form.$errors.partner_a"
            label="Partner A"
            required
        />

        <v-text-field
            v-model="form.partner_a0"
            :error-messages="form.$errors.partner_a0"
            label="Partner A0"
        />





      <div class="d-flex justify-center mb-3">
            <v-btn
                color="primary"
                outlined
                @click="save"
            >
                {{$t('general.create')}}
            </v-btn>
        </div>

    </v-form>
</template>

<script>
import ApiService from '@/services/ApiService'
import * as rules from 'vuelidate/lib/validators'
import { minLength, sameAs }  from 'vuelidate/lib/validators'

export default {

    created() {
        this.form = this.createForm()
    },

    data() {
        return {
            form: null,
            resellers:[],
        };
    },

    validations: {
        form: {
            name: { required: rules.required },
            app: { required: rules.required },
            months_duration: { required: rules.required },
            price: { required: rules.required },
            status: { required: rules.required },
            payment_type: { required: rules.required },
            number_of_allowed_modules: { required: rules.required },
            reseller_id: { required: rules.required },
            partner_c:  { numeric: rules.numeric, minValue: 1},
            partner_b:  { required: rules.required, numeric: rules.numeric, minValue : 1},
            partner_a:  { required: rules.required , numeric: rules.numeric, minValue: 1},
            partner_a0:  { numeric: rules.numeric, minValue: 1},
            contract_config : {},
        }
    },

    computed: {
        isFiscal() {
            return locale === 'me'
        },
    },

    methods: {
      createForm() {
        //Populate resellers array
        this.getResellers();
        return new CodForm(this, 'form')

      },
      async getResellers() {
        const response = await ApiService.get("/admin/resellers");
        this.resellers = response.data.resellers
      },
      /**
       * Save contract
       */
      save() {

        if (!this.form.validate()) return

        this.form.contract_config = {
          partner_c: this.form.partner_c,
          partner_b: this.form.partner_b,
          partner_a: this.form.partner_a,
          partner_a0: this.form.partner_a0,
        };

        delete this.form.partner_c;
        delete this.form.partner_b;
        delete this.form.partner_a0;
        delete this.form.partner_a;

        ApiService.post(`/admin/contracts`, this.form).then(response => {
          this.$emit('save', response.data.contract)
          this.$snackbar('Contract created', 'success');
          this.form = this.createForm();
        });
      },
    }
};
</script>


<style scoped>
.form{
    background: white;
    padding: 20px;
}
</style>
