<template>
    <div class="efi-transactions">
        <v-row class="mb-4">
            <v-col cols="12" sm="5">
                <v-text-field
                    v-model="filter"
                    :label="$t('efi_transactions.search')"
                    append-icon="far fa-search"
                    clearable
                    clear-icon="mdi mdi-magnify"
                    single-line
                    hide-details
                    @input="filterEfiTransactions()"
                />
            </v-col>

            <v-col cols="5" sm="3">
                <v-select
                    v-model="type"
                    :items="types"
                    :label="$t('efi_transactions.type')"
                    hide-details
                />
            </v-col>

            <v-col cols="5" sm="3">
                <v-select
                    v-model="status"
                    :items="statuses"
                    :label="$t('efi_transactions.status')"
                    hide-details
                />
            </v-col>

            <v-col class="d-flex align-end justify-end" cols="2" sm="1">
                <v-btn
                    icon
                    @click="getEfiTransactions()"
                >
                    <v-icon>mdi mdi-refresh</v-icon>
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            class="operations striped"
            :headers="headers"
            :items="transactions"
            :options.sync="pagination"
            :server-items-length="total"
            :footer-props="{'items-per-page-options': [20, 50, 100, 200]}"
            :items-per-page.sync="pagination.itemsPerPage"
            :loading="loading"
            :mobile-breakpoint="0"
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    More info about {{ item.request_type }}
                </td>
            </template>
            <template v-slot:item="{ item }">
                <tr>
                    <td>{{ item.created_at | dateFromUtc }}</td>
                    <td>{{ item.tenant.email }}</td>
                    <td>{{ item.request_type }}</td>
                    <td :class="{
                        'error--text': item.response_status === 'failed',
                        'success--text': item.response_status === 'success',
                    }">
                        {{ item.response_status }}
                    </td>
                    <td>{{ item.fault_string }}</td>
                    <td>{{ item.error_string }}</td>
                    <td class="w60" align="center">
                        <v-menu bottom transition="slide-y-transition">
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon>
                                    <v-icon>mdi mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <template>
                                    <v-list-item
                                        class=""
                                        :key="1"
                                        @click="showDetails(item)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-eye-outline</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>Details</v-list-item-title>
                                    </v-list-item>

                                    <v-divider />
                                </template>

                                <v-list-item
                                    :key="2"
                                    @click=""
                                >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                        <v-icon>mdi mdi-content-copy</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>{{$t('general.copy')}}</v-list-item-title>
                                </v-list-item>

                                <v-divider />

                                <v-list-item
                                    :key="3"
                                    @click=""
                                >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                         <v-icon>mdi mdi-pencil-outline</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title>{{$t('general.edit')}}</v-list-item-title>
                                </v-list-item>

                                <v-divider />

                                <v-list-item
                                    :key="4"
                                    @click=""
                                >
                                    <v-list-item-action class="mr-4 flex justify-content-center">
                                        <v-icon class="error--text">mdi mdi-delete-outline</v-icon>
                                    </v-list-item-action>
                                    <v-list-item-title class="error--text">{{$t('general.delete')}}</v-list-item-title>
                                </v-list-item>

                            </v-list>
                        </v-menu>
                        
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog v-model="details" width="700" height="90%">
            <div v-if="curTransaction" class="transaction-details">
                <v-tabs
                    v-model="tab"
                    background-color="#363c4a"
                    dark
                >
                    <v-tab>
                        <span v-if="curTransaction.order">
                            Receipt
                        </span>
                        <span v-else-if="curTransaction.cashRegister">
                            Cash Register
                        </span>
                        <span v-else-if="curTransaction.cashRegisterTransaction">
                            Cash Register Transaction
                        </span>
                    </v-tab>
                    <v-tab>Request body</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <pre v-if="curTransaction.order" class="code">
                            {{ curTransaction.order }}
                        </pre>
                        <pre v-else-if="curTransaction.cashRegister" class="code">
                            {{ curTransaction.cashRegister }}
                        </pre>
                        <pre v-else-if="curTransaction.cashRegisterTransaction" class="code">
                            {{ curTransaction.cashRegisterTransaction }}
                        </pre>
                    </v-tab-item>

                    <v-tab-item>
                        <pre class="code">{{ curTransaction.request_body }}</pre>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </v-dialog>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    created() {
    },

    data() {
        return {
            loading: false,
            details: false,
            tab: 0,

            types: [
                'registerTCR',
                'registerCashDeposit',
                'registerInvoice',
            ],

            statuses: [
                'success',
                'failed',
            ],

            total: 0,
            pagination: {
                sortBy: ['created_at'],
                itemsPerPage: 100,
                page: 1,
                sortDesc: [true]
            },

            headers: [
                { text: this.$t('general.time'), value: 'created_at' },
                { text: this.$t('general.customer'), value: 'tenant.email' },
                { text: this.$t('general.type'), value: 'request_type' },
                { text: this.$t('general.status'), value: 'response_status' },
                { text: this.$t('efi_transactions.error_string'), value: 'error_string' },
                { text: this.$t('efi_transactions.fault_string'), value: 'fault_string' },
                { text: '', sortable: false }
            ],

            filter: null,
            status: 'failed',
            type: null,

            transactions: [],
            curTransaction: null
        };
    },

    watch: {
        pagination: {
            deep: true,
            handler() {
                this.getEfiTransactions();
            },
        },

        status() {
             this.getEfiTransactions();
        },

        type() {
             this.getEfiTransactions();
        }
    },

    methods: {
        /**
         * Get Paginated, Filtered and Sorted transactions from server
         */
        getEfiTransactions() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

            const params = {
                page: page,
                itemsPerPage: itemsPerPage,
                sortBy: sortBy[0] ? sortBy[0] : 'created_at',
                sortOrder: sortDesc[0] ? 'desc' : 'asc',
            }

            if(this.filter) params['filter'] = this.filter
            if(this.status) params['status'] = this.status
            if(this.type) params['type'] = this.type

            this.loading = true;
            ApiService.get('/admin/efi-transactions', { params: params }).then(response => {
                this.loading = false;
                this.transactions = response.data.data.map(transaction => {
                    if(transaction.order) {
                        transaction.order.taxes = JSON.parse(transaction.order.taxes)
                    }

                    return transaction
                });
                this.total = response.data.meta.total;
            });
        },

        /**
         * Filter Tenants
         */
        filterEfiTransactions: _.debounce(function() {
            this.getEfiTransactions();
        }, 500),

        showDetails(transaction) {
            this.curTransaction = transaction
            this.details = true
        }
    }
};
</script>

<style lang="scss" scoped>
.efi-transactions {
    padding: 20px;
    background: white;
    border-radius: 4px;
    border: 1px solid #d3d5dc;
}

.transaction-details {
    background: white;
    
    .code {
        padding: 20px;
        white-space: break-spaces;
        word-break: break-word;
    }
}
</style>
