import ApiService from '@/services/ApiService';
import Store from '@/store';
import * as mutationTypes from '@/store/mutationTypes';

const USER_TOKEN_KEY = 'access_token';

const UserService = {

    /**
     * Set User
     */
    setUser(user){

        
        // Save User in the store

        Store.commit(mutationTypes.SET_USER, { user: user ? user : "" });

        // Set User Header
        if(!user){
            ApiService.removeUserHeader();
            ApiService.unmountInterceptor401();
            return;
        }

        ApiService.setUserHeader();
        ApiService.mountInterceptor401();
    },

    /**
     * Remove User from the Store
     */
    removeUser() {
        this.setUser(null);
    },

    /**
     * Get User Token
     */
    getToken() {
        return localStorage.getItem(USER_TOKEN_KEY);
    },

    /**
     * Set User Token
     */
    setToken(accessToken) {
        localStorage.setItem(USER_TOKEN_KEY, accessToken);
    },

    /**
     * Remove User Token
     */
    removeToken() {
        localStorage.removeItem(USER_TOKEN_KEY);
    }
};

export default UserService;

export { UserService };
