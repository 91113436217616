<template>
    <v-form
        v-if="form"
        ref="form"
        class="venue-form"
    >
        <v-text-field
            v-model="form.name"
            :error-messages="form.$errors.name"
            :label="$t('general.name')"
            :disabled="$store.state.user.role == 'reseller'"
            required
        />

        <v-text-field
            v-model="form.email"
            :error-messages="form.$errors.email"
            :label="$t('general.email')"
            :disabled="$store.state.user.role == 'reseller'"
            required
        />

        <v-text-field
            v-model="form.phone"
            :error-messages="form.$errors.phone"
            label="Phone"
            :label="$t('general.phone')"
            :disabled="$store.state.user.role == 'reseller'"
            required
        />

        <v-select
          :items="['aria', 'allegra']"
          v-model="form.app"
          :label="$t('general.app')"
          :disabled="$store.state.user.role == 'reseller'"
        >
        </v-select>

        <v-select
            :items="resellers"
            :error-messages="form.$errors.reseller_id"
            v-model="form.reseller_id"
            :label="$t('general.reseller')"
            item-text="name"
            item-value="id"
            :disabled="$store.state.user.role == 'reseller'"
        >
        </v-select>

        <v-text-field
            v-model="form.password"
            :error-messages="form.$errors.password"
            :label="$t('general.password')"
            :append-icon="showPassword ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword"
        />

        <v-text-field
            v-model="form.password_confirmation"
            :error-messages="form.$errors.passwordConfirmation"
            :label="$t('general.confirm_password')"
            :append-icon="showPasswordConfirmation ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"
            :type="showPasswordConfirmation ? 'text' : 'password'"
            @click:append="showPasswordConfirmation = !showPasswordConfirmation"
        />
        <br>
        <v-text-field
            v-model="form.pin"
            :error-messages="form.$errors.pin"
            :label="$t('general.pin')"
            :append-icon="showPin ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"
            :type="showPin ? 'text' : 'password'"
            @click:append="showPin = !showPin"
        />

        <v-text-field
            v-model="form.pin_confirmation"
            :error-messages="form.$errors.pin_confirmation"
            :label="$t('general.confirm_pin')"
            :append-icon="showPinConfirmation ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"
            :type="showPinConfirmation ? 'text' : 'password'"
            @click:append="showPinConfirmation = !showPinConfirmation"
        />

        <div class="d-flex justify-center mb-3">
            <v-btn
                color="primary"
                outlined
                @click="save"
            >
                {{$t('general.save')}}
            </v-btn>
        </div>

    </v-form>
</template>

<script>
import ApiService from '@/services/ApiService'
import * as rules from 'vuelidate/lib/validators'
import { minLength, sameAs }  from 'vuelidate/lib/validators'

export default {
    props: {
        tenant: {
            type: Object,
            default: () => null
        }
    },

    created() {
        this.form = this.createForm(this.tenant)
    },

    data() {
        return {
            form: null,
            showPassword: false,
            showPasswordConfirmation: false,
            showPin: false,
            showPinConfirmation: false,
            resellers:[]
        };
    },

    validations: {
        form: {
            name: { required: rules.required },
            email: { required: rules.required, email: rules.email },
            phone: { required: rules.required },
            app: { required: rules.required },
            reseller_id: { required: rules.required },
            password: { minLength: minLength(8) },
            passwordConfirmation: {
                sameAs: sameAs(function () { return this.password })
            },
            pin: { minLength: minLength(4) },
            pin_confirmation: {
                sameAs: sameAs(function () { return this.form.pin })
            }
        }
    },

    watch: {
        tenant(val) {
            this.form = this.createForm(val)
        }
    },

    computed: {
        isFiscal() {
            return locale === 'me'
        },
    },

    methods: {
        createForm(tenant = null) {
            //Populate resellers array
            this.getResellers();

            return new CodForm(this, 'form', {
                id: tenant ? tenant.id : null,
                name: tenant ? tenant.name : null,
                email: tenant ? tenant.email : null,
                password: null,
                password_confirmation: null,
                pin: null,
                pin_confirmation: null,
                phone: tenant ? tenant.phone : null,
                app: tenant ? tenant.app : null,
                reseller_id: tenant ? tenant.reseller?.id : null,
            })

        },
        async getResellers(){
            const response = await ApiService.get("/admin/resellers");
            this.resellers = response.data.resellers
        },
        /**
         * Save Venue
         */
        save() {
            if (!this.form.validate()) return
            ApiService.patch(`/admin/tenants/${this.form.id}`, this.form)
            .then(response => {
                this.$emit('save', response.data.tenant)
                this.$snackbar('Tenant updated', 'success');
            }).catch(err => {
                this.$snackbar(err.response.data.message, 'error');
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.date-picker{
    margin: 0 auto;
}
</style>
