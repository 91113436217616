// Load JavaScript dependencies
require('./bootstrap');

import Vue from 'vue';
import store from '@/store';
import App from '@/App.vue';

import router from '@/router';

import vuetify from '@/plugins/vuetify';

import i18n from '@/i18n';


Vue.config.productionTip = false;

// Global Mixin
import GlobalMixin from './mixins/global';
Vue.mixin(GlobalMixin);

new Vue({
    components: { App },
    store,
    router,
    vuetify,
    i18n,
    render: h => h(App)
}).$mount('#app');

