<template>
    <div class="login" v-on:keyup.enter="login">
        
        <v-card width="350">

            <v-card-text class="pa-5">
                <div class="mb-5 headline font-weight-light text-center">
                    Login
                </div>
                
                <v-form>
                    <v-text-field
                        v-model="form.email"
                        :error-messages="form.$errors.email"
                        label="E-mail Address"
                        prepend-icon="mdi mdi-account-circle-outline"
                        @blur="form.validate('email')"
                        @input="delete form.$errors.email"
                        v-on:keyup.enter="login"
                    />
                        
                    <v-text-field
                        v-model="form.password"
                        :error-messages="form.$errors.password"
                        autocomplete="current-password"
                        label="Password"
                        type="password"
                    />

                    <v-btn
                        class="mt-4"
                        color="success"
                        :loading="form.$busy"
                        depressed
                        block
                        @click="login"
                    >
                        Login
                    </v-btn>
                    
                    <div class="d-flex justify-space-between mt-5">
                        <a href="#" @click="$router.push({ name: 'forgotPassword' })">
                            Forgot password?
                        </a>

                        <a href="#" @click="$router.push({ name: 'register' })">
                            Don't have an account?
                        </a>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>

    </div>
</template>

<script>
import UserService from '@/services/UserService'
import * as rules from 'vuelidate/lib/validators'

export default {
    data() {
        return {
            form: new CodForm(this,'form', {
                email: null,
                password: null,
            })
        };
    },

    validations: {
        form: {
            email: { required: rules.required, email: rules.email },
            password: { required: rules.required },
        }
    },

    methods: {

        /**
         * User Login
         */
        login() {
            if (!this.form.validate()) {
                return;
            }

            ApiService.post('/login', this.form).then(response => {

                // Set Token
                UserService.setToken(response.data.access_token);

                // Set User
                UserService.setUser(response.data.user);

                this.$router.push({ name: 'customers' });
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.login {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), 
        url("https://demos.creative-tim.com/vuetify-material-dashboard-pro/img/lock.9ae20e99.jpg");
    background-position: center center;
    background-size: cover;
}
</style>
