<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
    >
        <p> {{$t('general.write')}} <b> {{tenant.email}} </b> {{$t('general.confirm_action')}} </p>
        <v-text-field
            v-model="email"
            :rules="emailRules"
            label="E-mail"
            required
        />
        <div class="d-flex justify-center mb-3">
            <v-btn
                color="primary"
                outlined
                :disabled="!valid"
                @click="deleteItem"
            >
            {{$t('general.delete')}}
            </v-btn>
        </div>
  </v-form>
</template>

<script>
export default {
    props: {
        tenant: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            valid: true,
            email: "",
            emailRules: [
                (v) =>
                    this.tenant.email === this.email ||
                    this.$t('validations.deleting_venue'),
            ],
        };
    },
    methods: {
        async deleteItem() {

            if(this.tenant.email !== this.email){
                this.$snackbar(`Email: ${this.email} doesn't match with ${this.tenant.email} `,"error");
                return;
            };


            try {
                await ApiService.delete(`/admin/tenants/${this.tenant.id}`).then((res) => {
                    this.$emit("delete");
                    this.$snackbar('Tenant deleted', 'success');
                });
            } catch (error) {
                if (error.response.status === 422) {
                    this.$snackbar(`Deleting ${this.tenant.email} failed`,"error");
                }
            }
        },

    },
};
</script>

<style lang="scss" scoped>
</style>
