<template>
	<v-container>
		<v-row>
			<v-col cols="12" :md="json.getStatus || json.getLicense ? '4': '6'" :sm="12" :lg="json.getStatus || json.getLicense ? '4': '6'">
                <v-card style="padding: 20px;">
                    <h3 style="padding-bottom: 10px">{{$t('licenses.data_for_sending')}}</h3>
                    <template
                        v-for="command in commands"
                    >
                        <v-checkbox
                            v-model="command.checked"
                            :label="command.name"
                            class="shrink mr-0 mt-0">
                            :key="command.id"
                        </v-checkbox>
                    </template>
                    <v-divider/> <br>
                    <v-row style="margin-left:2px">
                        <v-btn
                            outlined
                            @click="sendCommands"
                        >
                            <v-icon>mdi mdi-send</v-icon>
                            {{$t('general.send')}}
                        </v-btn>
                        <v-btn
                            outlined
                            @click="refresh"
                            style="margin-left:10px"
                        >
                            <v-icon>mdi mdi-restore</v-icon>
                        </v-btn>
                    </v-row>
                    <div class="notified-at-container">
                        <h5 v-if="licenseDetails.notified_online_at"
                            class="notified-at-el"
                        >{{$t("general.notified_online_at")}}: {{ licenseDetails.notified_online_at | dateFromUtc(':ss') }}</h5>
                    </div>

                </v-card>
			</v-col>
			<v-col :cols="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs ? '12': '8'" >
                <div v-if="json.getLog" class="header link-class">
                    {{$t("licenses.commands.log_file")}}: <a :href="`${json.getLog.url}`" target="_blank"> {{json.getLog.url}} </a>
                </div>
                <v-row>
                    <v-col :md="json.getStatus && !json.getLicense ? '12' : '6'" cols="12" v-if="json.getStatus">
                        <div class="header"> {{$t("licenses.commands.status_data")}} </div>
                        <v-card class="json-container">
                            <vue-json-pretty :collapsedOnClickBrackets="true" :path="'res'" :data="json.getStatus"> </vue-json-pretty>
                        </v-card>
                    </v-col>
                    <v-col :md="json.getLicense && !json.getStatus ? '12' : '6'" cols="12" v-if="json.getLicense">
                        <div class="header"> {{$t("licenses.commands.local_license")}} </div>
                        <v-card class="json-container">
                            <vue-json-pretty :collapsedOnClickBrackets="true" :path="'res'" :data="json.getLicense"> </vue-json-pretty>
                        </v-card>
                    </v-col>
                </v-row>
			</v-col>
		</v-row>
        <v-row>
            <v-card-text v-if="dataLastInvoices.length > 0">
                <div class="header"> {{$t("licenses.last_invoices")}}  </div>
                <v-data-table
                    :headers="headersLastInvoices"
                    :items="dataLastInvoices"
                    :items-per-page="5"
                    class="elevation-1"
                >
                    <template v-slot:item.result="{ item }">
                        <span class="expand-row"  v-if="item.result" @click="showIdDialog(item.result, 'Result')">{{ item.result.substring(0,32) }}...</span>
                    </template>
                    <template v-slot:item.request="{ item }">
                        <span class="expand-row" v-if="item.request" @click="showIdDialog(item.request, 'Request')">{{ item.request.substring(0,20) }}...</span>
                    </template>
                    <template v-slot:item.created_at="{ item }">
                        <span>{{ item.created_at | dateFromUtc(':ss')   }}</span>
                    </template>
                    <template v-slot:item.updated_at="{ item }">
                        <span>{{ item.updated_at | dateFromUtc(':ss')   }}</span>
                    </template>
                </v-data-table>
            </v-card-text>
		</v-row>
		<v-row>
            <v-card-text v-if="dataLastAudit.length > 0">
              <div class="header"> {{$t("licenses.last_audit_package")}} </div>
				<v-data-table
					:headers="headersLastAudit"
    				:items="dataLastAudit"
					:items-per-page="5"
					class="elevation-1"
				>
	                <template v-slot:item.value="{ item }">
	                   <span class="expand-row" v-if="item.value" @click="showIdDialog(item.value, 'Value')">{{ item.value.substring(0,20) }}...</span>
	                </template>
	                <template v-slot:item.created_at="{ item }">
	                   <span>{{ item.created_at | dateFromUtc(':ss')   }}</span>
	                </template>
	                <template v-slot:item.submitted_at="{ item }">
	                    <span>{{ item.submitted_at | dateFromUtc(':ss')   }}</span>
	                </template>
	            </v-data-table>
            </v-card-text>
		</v-row>

        <v-dialog
            v-model="show.idViewer"
            width="700"
        >
        <div class="header">{{selectedType}}</div>
            <div>
                <textarea v-model="selectedId" name="idViewer" id="" cols="30" rows="20" readonly></textarea>
            </div>
        </v-dialog>
	</v-container>

</template>

<script>
import axios from 'axios';

import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

export default {
    components:{
        VueJsonPretty,
    },
    data() {
        return {
            commands: [
                {
                    id: 1,
                    type: "getLog",
                    name: this.$t("licenses.commands.log_file"),
                    checked: false,
                },
                {
                    id: 2,
                    type: "getStatus",
                    name: this.$t("licenses.commands.status_data"),
                    checked: false,
                },
                {
                    id: 3,
                    type: "getLicense",
                    name: this.$t("licenses.commands.local_license"),
                    checked: false,
                },
                {
                    id: 4,
                    type: "getDbSamples",
                    name: this.$t("licenses.commands.samples_from_database"),
                    checked: false,
                },
                {
                    id: 5,
                    type: "hardReset",
                    name: this.$t("licenses.commands.hard_reset"),
                    checked: false,
                },
                {
                    id: 6,
                    type: "resendRejectedPackages",
                    name: this.$t("licenses.commands.resend_rejected_packages"),
                    checked: false,
                },
                {
                    id: 7,
                    type: "startAudit",
                    name: this.$t("licenses.commands.start_audit"),
                    checked: false,
                },
                {
                    id: 8,
                    type: "checkForUpdate",
                    name: this.$t("licenses.commands.check_for_update"),
                    checked: false,
                },
                {
                    id: 9,
                    type: "forceUpdate",
                    name: this.$t("licenses.commands.force_update"),
                    checked: false,
                },
                {
                    id: 10,
                    type: "getLastToken",
                    name: this.$t("licenses.commands.get_last_token"),
                    checked: false,
                },
                {
                    id: 11,
                    type: "exportDatabase",
                    name: this.$t("licenses.commands.export_database"),
                    checked: false,
                },
                {
                    id: 12,
                    type: "deleteProcessedPackages",
                    name: this.$t("licenses.commands.delete_processed_packages"),
                    checked: false,
                },
            ],
            licenseDetails: null,
            getStatusData: "",
            getLicenseData: "",
            headersLastAudit: [],
            dataLastAudit: [],
            headersLastInvoices: [],
            dataLastInvoices: [],
            show:{
                idViewer: false
            },
            selectedId: "",
            selectedType: "",
            json: {getlog: {url: ""}},
            loading: true
        };
    },
    created() {

        let retrievedObject = {};

        if (this.$store.state.licenseDetails) {
            localStorage.setItem(
                "licenseDetails",
                JSON.stringify(this.$store.state.licenseDetails)
            );
            this.licenseDetails = this.$store.state.licenseDetails;
        } else {
            retrievedObject = localStorage.getItem("licenseDetails");
            this.licenseDetails = JSON.parse(retrievedObject);
        }

        // Showing the name and id of customer lpfr
        EventBus.$emit('route-update', { title: this.licenseDetails.venue + ' - ' + this.licenseDetails.id });

    },

    methods: {
        sendCommands() {
            const params = {
                machine_id: this.licenseDetails.machine_id,
                commands: this.commands
                    .filter((command) => command.checked)
                    .map((command) => command.type),
            };

            ApiService.post(`/admin/esdc/commands`, params).then((response) =>
                this.$snackbar(response.data, "success")
            );
        },
        refresh() {

            ApiService.get(`/admin/esdc/licenses/${this.licenseDetails.id}`)
            .then(response => {

                this.json = response.data;

                if(this.json.getDbSamples){
                    this.getLastAuditData();
                    this.getLastInvoiceData();
                }

                if(!this.json.getDbSamples && !this.json.getLog && !this.json.getLicense && !this.json.getStatus ) this.loading = true;

            });


        },
        getLastAuditData(){
            let headersLastAudit = [];
			for(let prop in this.json.getDbSamples.data.lastAuditPackages[0]){
				headersLastAudit.push({text: prop, value: prop})
			}
            this.headersLastAudit = headersLastAudit;
            this.dataLastAudit = this.json.getDbSamples.data.lastAuditPackages;
        },
        getLastInvoiceData(){
            let headersLastInvoices = [];
			for(let prop in this.json.getDbSamples.data.lastInvoices[0]){
				headersLastInvoices.push({text: prop, value: prop})
			}
            this.headersLastInvoices = headersLastInvoices                       ;
            this.dataLastInvoices = this.json.getDbSamples.data.lastInvoices;
        },
        showIdDialog(item, type){

            this.show.idViewer = true;
            this.selectedType = type;

            let parsedJSON =  JSON.parse(item);
            this.selectedId = JSON.stringify(parsedJSON,null,"\t");

        }
    },
};
</script>

<style scoped>
textarea {
    resize: none;
    background-color: white;
    border: 1px solid black;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
}

a {
    text-decoration: none;
}

.truncate {
    max-width: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.header {
    padding: 16px;
    background: #363c4a;
    color: white;
    border-radius: 2px;
}

.expand-row{
    cursor: pointer;
}
.action-buttons{
    text-align: center;
}
.json-container{
    padding: 5%;
    max-height: 645px;
    overflow-y: auto;
}

.link-class{
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.notified-at-container{
    height: 80px;
    position: relative;
}
.notified-at-el{
    position: absolute;
    bottom: 0;
    left: 0;
}
</style>
