<template>
    <div class="admin-layout">

        <!-- Admin App Bar -->
        <admin-app-bar />

        <!-- Admin Navigation Drawer -->
        <admin-navigation-drawer />

        <!-- Admin Main Content -->
        <v-main>
            <div class="content">
                <router-transition>
                    <router-view />
                </router-transition>
            </div>
        </v-main>
        
    </div>
</template>

<script>
import RouterTransition from '@/router/RouterTransition';
import AdminAppBar from '@/views/admin/AdminAppBar';
import AdminNavigationDrawer from '@/views/admin/AdminNavigationDrawer';

export default {
    components: {
        RouterTransition,
        AdminAppBar,
        AdminNavigationDrawer
    },

    data() {
        return {
        };
    },
};
</script>

<style scoped lang="scss">
.admin-layout {
    height: 100%;

    .v-main {
        height: 100%;
        
        .content {
            padding: 20px;
            height: 100%;
            position: relative;
            background: #e4e6ee;

            @media screen and (max-width: 600px) {
                padding: 10px;
            }
        }
    }
}
</style>
