<template>
  <div class="tenants">
    <v-row>
      <v-text-field
          v-model="filter"
          class="search"
          :label="$t('customers.search')"
          append-icon="far fa-search"
          clearable
          clear-icon="mdi mdi-magnify"
          single-line
          hide-details
          @input="filterUsers()"
      />
      <v-col align="right">
        <v-btn
            outlined
            @click="show.createUser = true;"
        >
          <v-icon>mdi mdi-plus</v-icon>
          {{$t('general.create')}}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
        class="operations striped"
        :headers="headers"
        :items="users"
        :options.sync="pagination"
        :server-items-length="total"
        :footer-props="{'items-per-page-options': [50, 100, 200, 500]}"
        :items-per-page.sync="pagination.itemsPerPage"
        :loading="loading"
        :mobile-breakpoint="0"
    >
      <template v-slot:item="{ item, index }">
        <tr>
          <td>{{ index + 1 }}</td>
          <td>{{ item.name }}</td>
          <td>{{ item.reseller ? item.reseller.name : '' }}</td>
          <td>{{ item.email }}</td>
          <td>{{ item.role }}</td>
          <td>{{ item.created_at | dateFromUtc }}</td>
          <td class="w60" align="center">

            <v-menu bottom transition="slide-y-transition">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon>
                  <v-icon>mdi mdi-dots-horizontal</v-icon>
                </v-btn>
              </template>

              <v-list>

                <v-list-item
                    :key="1"
                    @click="editUser(item)"
                >
                  <v-list-item-action class="mr-4 flex justify-content-center">
                    <v-icon>mdi mdi-account-edit</v-icon>
                  </v-list-item-action>
                  <v-list-item-title>{{$t('general.edit_tenant')}}</v-list-item-title>
                </v-list-item>

              </v-list>
            </v-menu>

          </td>
        </tr>
      </template>
    </v-data-table>


    <v-dialog
        v-model="show.editUser"
        width="500"
    >
      <div v-if="curUser" class="edit-venue">
        <div class="edit-venue-header">
          {{$t('general.edit_tenant')}}
        </div>
        <user-form
            class="edit-venue-content"
            :tenant="curUser"
            @save="onTenantUpdate"
        />
      </div>
    </v-dialog>

    <v-dialog
        v-model="show.createUser"
        width="500"
    >
      <div class="edit-venue">
        <div class="edit-venue-header">
          {{$t('general.create')}}
        </div>
        <user-create-form
            class="edit-venue-content"
            @save="onTenantUpdate"
        />
      </div>
    </v-dialog>

  </div>
</template>

<script>
import _ from 'lodash'

import UserForm from '@/views/admin/Users/UserForm';
import UserCreateForm from '@/views/admin/Users/UserCreateForm';
import VueJsonPretty from "vue-json-pretty";

export default {
  components: {
    VueJsonPretty,
    UserForm,
    UserCreateForm
  },

  data() {
    return {
      loading: false,
      total: 0,
      pagination: {
        sortBy: ['created_at'],
        itemsPerPage: 200,
        page: 1,
        sortDesc: [true],
      },

      headers: [
        { text: this.$t('general.number'), sortable: false },
        { text: this.$t('general.name'), value: 'name' },
        { text: this.$t('general.reseller'), value: 'reseller.name' },
        { text: this.$t('general.email'), value: 'email' },
        { text: this.$t('general.role'), value: 'app' },
        { text: this.$t('general.created_at'),  value: 'created_at' },
        { text: '', sortable: false }
      ],

      filter: null,

      users: [],

      curUser: null,
      show: {
        editUser: false,
        createUser: false,
      },
      form: null,

      componentKey: 0,
    };
  },

  watch: {
    pagination: {
      deep: true,
      handler() {
        this.getUsers();
      },
    },
  },

  methods: {


    /**
     * Get Paginated, Filtered and Sorted users from server
     */
    getUsers() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

      const params = {
        page: page,
        itemsPerPage: itemsPerPage,
        sortBy: sortBy[0] ? sortBy[0] : 'created_at',
        sortOrder: sortDesc[0] ? 'desc' : 'asc',
      }

      if(this.filter) params['filter'] = this.filter

      this.loading = true;
      ApiService.get('/admin/users', { params: params }).then(response => {
        this.loading = false;
        this.users = response.data.data;
        this.total = response.data.total;
      });
    },




    editUser(item) {

      this.curUser = item
      this.show.editUser = true
    },

    /**
     * On tenant update replace the current tenant in the array with the updated one
     */
    onTenantUpdate(){
      this.show.editUser = false
      this.show.createUser = false
      this.curUser = null
      this.getUsers();
    },




    /**
     * Filter Users
     */
    filterUsers: _.debounce(function() {
      this.getUsers();
    }, 500),
    resetForm(){
      this.componentKey++;
      this.form.cert_password = null;
      this.show.uploadCertificate = false;
    },
    openTenantDetails(item){
      this.$router.push({ name: "tenantDetails", params:{ id: item.id } })
    },
  }
};
</script>

<style lang="scss" scoped>
.tenants {
  padding: 20px;
  background: white;
  border-radius: 4px;
  border: 1px solid #d3d5dc;

  .search {
    max-width: 400px;
    margin-bottom: 16px;
  }
}

.edit-venue {
  .edit-venue-header {
    padding: 16px;
    background: #363c4a;
    color: white;
  }

  .edit-venue-content {
    padding: 16px;
    background: white;
  }
}
</style>