<template>
    <div class="bank-statements">
        <v-row>
            <v-col>
                <v-text-field
                    v-model="filter"
                    :label="$t('general.search')"
                    append-icon="far fa-search"
                    clearable
                    clear-icon="mdi mdi-magnify"
                    single-line
                    hide-details
                    @input="filterBankStatements()"
                />
            </v-col>
            <v-col>
                <v-row>
                    <v-checkbox
                       v-for="filter in filtersByStatus"
                       :key="filter.id"
                       v-model="filter.checked"
                       :label="$t(`bank_statements.${filter.status}`)"
                       class="shrink mr-0 mt-0"
                       @change="filterBankStatements()"
                    >
                   </v-checkbox>
                </v-row>
            </v-col>
            <v-col align="right">
                <v-btn
                    outlined
                    @click="onOpenImportBankStatements()"
                >   
                    <v-icon>mdi mdi-application-import</v-icon>
                    {{$t('general.import')}}
                </v-btn>
            </v-col>
           
        </v-row>

        <v-data-table
            class="operations striped"
            :headers="headers"
            :items="bankStatements"
            :options.sync="pagination"
            :server-items-length="total"
            :footer-props="{'items-per-page-options': [20, 50, 100, 200]}"
            :items-per-page.sync="pagination.itemsPerPage"
            :loading="loading"
            :mobile-breakpoint="0"
        >
            <template v-slot:item="{ item, index }">
                <tr>
                    <td>{{ item.statement_date | dateFromUtc }}</td>
                    <td>{{ item.statement_number }}</td>
                    <td v-if="item.company">{{ item.company.name }}</td>
                    <td v-else></td>
                    <td>{{ item.in_amount_reference }}</td>
                    <td>{{ item.out_amount_reference }}</td>
                    <td>{{ item.payer_name }}</td>
                    <td>{{ item.payer_account_number }}</td>
                    <td>{{ item.transaction_description }}</td>
                    <td>{{ item.amount | twoDecimals }}</td>
                    <td 
                        v-if="item.match_status === 'confirmed'"
                        style="text-align: center;"
                    >
                        <v-icon style="color:green;">mdi mdi-check-circle</v-icon>
                    </td>
                    <td 
                        v-if="item.match_status === 'unconfirmed'"
                        style="text-align: center;"
                    >
                        <v-icon>mdi mdi-clock-outline</v-icon>
                    </td>
                    <td 
                        v-if="item.match_status === 'discarded'"
                        style="text-align: center;"
                    >
                        <v-icon style="color:red;">mdi mdi-close-circle</v-icon>
                    </td>
                    <td>
                        <v-menu 
                            v-if="item.match_status !== 'confirmed'"
                            bottom transition="slide-y-transition"
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" icon>
                                    <v-icon >mdi mdi-dots-horizontal</v-icon>
                                </v-btn>
                            </template>

                            <v-list>
                                <template>
                                    <v-list-item
                                        :key="1"
                                        @click="openDialogEditBankStatement(item)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon>mdi mdi-file-sync</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-title>
                                            {{$t('bank_statements.change_status')}}
                                        </v-list-item-title>
                                        <v-divider />
                                    </v-list-item>
                                    <v-divider />
                                    <v-list-item
                                        :key="2"
                                        v-if="item.company"
                                        @click="editBankStatement(item, true, index)"
                                    >
                                        <v-list-item-action class="mr-4 flex justify-content-center">
                                            <v-icon style="color:green;">mdi mdi-check-circle</v-icon>

                                            </v-list-item-action>
                                            <v-list-item-title>
                                                {{ $t('general.confirm')}}
                                            </v-list-item-title>
                                            <v-divider />
                                        </v-list-item>
                                    <v-divider />
                                </template>
                            </v-list>
                        </v-menu>
                    </td>
                </tr>
            </template>
        </v-data-table>

        <v-dialog
            v-model="show.importBankStatements"
            width="500"
        >
        <div>
            <div class="dialog-header">
                {{$t('bank_statements.import')}} 
            </div>
            <v-card style="padding: 2%">
                <v-col>
                    <v-col>
                        <v-autocomplete
                            v-if="$store.state.user.role === 'admin'"
                            v-model="reseller"
                            :label="$t('general.reseller')"
                            :items="resellers"
                            item-text="state"
                            item-value="abbr"
                            persistent-hint
                            return-object
                            single-line
                            clearable
                            @change="resellerRequired = false"
                        >
                        </v-autocomplete>
                        <p 
                            v-if="resellerRequired"
                            style="color:red; font-size:smaller"
                        >
                            {{$t('bank_statements.errors.reseller_required')}}
                        </p>
                        <v-file-input
                            v-model="bankStatementsFile"
                            :label="$t('admin_navigation.bank_statements')"
                            :placeholder="$t('admin_navigation.bank_statements') + ' fajl'"
                            prepend-icon=""
                            append-icon="fal fa-key"
                            clearable
                            clear-icon="fal fa-times"
                            outlined
                            validate-on-blur
                            @change="fileRequired = false"
                        />
                        <p 
                            v-if="fileRequired"
                            style="color:red; font-size:smaller"
                        >
                            {{$t('bank_statements.errors.file_required')}}
                        </p>
                    </v-col>
                </v-col>
                <div style="text-align:center">
                    <v-btn
                        outlined
                        :loading="importBankStatementsClicked"
                        @click="importBankStatements()"
                    >{{$t('general.import')}}
                    </v-btn>
                </div>
            </v-card>

        </div>
        </v-dialog>

         <v-dialog
            v-model="show.editBankStatement"
            width="500"
        >
        <div>
            <div class="dialog-header">
                {{$t('bank_statements.edit')}} 
            </div>
            <v-card style="padding: 2%">
                <v-col>
                    <v-col>
                        <v-autocomplete
                            v-model="company"
                            :label="$t('admin_navigation.companies')"
                            :items="companies"
                            item-text="state"
                            item-value="abbr"
                            persistent-hint
                            return-object
                            single-line
                            clearable
                            :loading="loadingCompanies"
                        >
                        </v-autocomplete>
                        <v-radio-group v-model="matchStatus">
                            <v-radio
                                :label="$t('bank_statements.confirmed')"
                                :value="'confirmed'"
                            ></v-radio>
                            <v-radio
                                :label="$t('bank_statements.unconfirmed')"
                                :value="'unconfirmed'"
                            ></v-radio>
                            <v-radio
                                :label="$t('bank_statements.discarded')"
                                :value="'discarded'"
                            ></v-radio>
                        </v-radio-group>
                    </v-col>
                </v-col>
                <div style="text-align:center">
                    <v-btn
                        :disabled="loadingCompanies"
                        outlined
                        @click="editBankStatement()"
                    >
                        {{$t('general.edit')}} 
                    </v-btn>
                </div>
            </v-card>

        </div>
        </v-dialog>
    </div>
</template>

<script>
import _ from 'lodash'

export default {
    data() {
        return {
            loading: false,
            total: 0,
            pagination: {
                sortBy: ['statement_number'],
                itemsPerPage: 100,
                page: 1,
                sortDesc: [true]
            },

            headers: [
                { text: this.$t('bank_statements.statement_date'), value: 'statement_date' },
                { text: this.$t('bank_statements.statement_number'), value: 'statement_number' },
                { text: this.$t('bank_statements.company'), value: 'company' },
                { text: this.$t('bank_statements.in_amount_reference'), value: 'in_amount_reference' },
                { text: this.$t('bank_statements.out_amount_reference'), value: 'out_amount_reference' },
                { text: this.$t('bank_statements.payer_account_name'), value: 'payer_name' },
                { text: this.$t('bank_statements.payer_account_number'), value: 'payer_account_number' },
                { text: this.$t('bank_statements.transaction_description'), value: 'transaction_description' },
                { text: this.$t('bank_statements.amount'), value: 'amount' },
                { text: this.$t('bank_statements.match_status'), value: 'match_status' },
                { text: '' },
            ],

            filter: null,
            bankStatements: [],
            show: {
                importBankStatements: false,
                editBankStatement: false
            },
            resellers:[],
            reseller: null,
            selectedBankStatement: {},
            matchStatus: '',
            companies: [],
            company: {},
            loadingCompanies: false,
            tempCompanies: [],
            bankStatementsFile: null,
            importBankStatementsClicked: false,
            resellerRequired: false,
            fileRequired: false,
            filtersByStatus: [
                {id: 1, status: "confirmed", checked: false},
                {id: 2, status: "unconfirmed", checked: true},
                {id: 3, status: "discarded", checked: false},
            ],

            
        };
    },
    watch: {
        pagination: {
            deep: true,
            handler() {
                this.getBankStatements();
            },
        },
    },
    created(){
        this.getResellers();
    },
    methods: {
        /**
         * Get Paginated, Filtered and Sorted bank statements from server
         */
        getBankStatements() {
            const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

            const params = {
                page: page,
                items_per_page: itemsPerPage,
                sort_by: sortBy[0] ? sortBy[0] : 'statement_number',
                sort_order: sortDesc[0] ? 'desc' : 'asc',
                filter_by_status: this.filtersByStatus.filter(status => status.checked).map(status => status.status)
            }

            if(this.filter) params['filter'] = this.filter

            this.loading = true;
            ApiService.get('/admin/bank-statement-items', { params: params }).then(response => {
                this.loading = false;
                this.bankStatements = response.data.data;
                this.total = response.data.total;
            }).catch(
                error => {
                  if (error.response.status === 403) {
                    this.$snackbar('You are not authorized to perform this action.', 'error');
                  }
                }
            );;
        },
        getResellers(){
            ApiService.get("/admin/resellers?locale=sr").then(
                response => response.data.resellers.forEach(reseller => this.resellers.push(reseller.name))
            );
        },
        getCompaniesByReseller(selectedCompany = ''){
            
            this.loadingCompanies = true;
            const params = {
                page: 1,
                itemsPerPage: 10000,
                sortBy: "created_at",
                reseller: this.selectedBankStatement.reseller.name
            };

            ApiService.get("/admin/companies",  { params: params } ).then(
                response => {
                    this.loadingCompanies = false;
                    this.companies = response.data.data.map((company) => {
                        return company.name;
                    });
                    this.tempCompanies = response.data.data;

                    this.company = selectedCompany;
                }
            );
        },
        importBankStatements(){

            if(this.$store.state.user.role === 'admin' && !this.reseller) {
                this.resellerRequired = true;
                return;
            }
            
            this.resellerRequired = false;

            if(!this.bankStatementsFile){
                this.fileRequired = true;
                return;
            }

            this.fileRequired = false;

            this.importBankStatementsClicked = true;    

            let formData = new FormData();
            formData.append('bank_statement', this.bankStatementsFile);

            if(this.reseller) formData.append('reseller_name', this.reseller);

            


            ApiService.post(`/admin/bank-statement-items/import`, formData,
                {
                    transformRequest: (data, headers) => {
                        headers.common['Content-Type'] = 'multipart/form-data';
                        return data;
                    }
                }
            ).then(response => {
                this.show.importBankStatements = false;
                this.bankStatementsFile = null;
                this.reseller = '';

                this.getBankStatements();
                this.importBankStatementsClicked = false;

                this.$snackbar('Bank statements imported.', 'success');
            }).catch((error)=> {
                this.importBankStatementsClicked = false;

                if(error.response.data.errors.reseller_name){
                    this.$snackbar(this.$t(`bank_statements.errors.reseller_required`), 'error')
                    return;
                } 
                error.response.data.errors.forEach(error=> { this.$snackbar(this.$t(`bank_statements.errors.${error.general}`), 'error')} )
            });

        },
        onOpenImportBankStatements(){
            this.show.importBankStatements = true;
            this.bankStatementsFile = null;
            this.reseller = null;
            this.fileRequired = false;
            this.resellerRequired = false
        },
        openDialogEditBankStatement(item){
            this.selectedBankStatement = item;

            if(item.company) this.getCompaniesByReseller(item.company.name)
            else this.getCompaniesByReseller();

            this.company = '';
            this.show.editBankStatement = true;
            this.matchStatus = item.match_status;
        },
        editBankStatement(item = null, fromMenu = false, index){

            let selectedCompany;
            let bankStatementIndex;

            if(fromMenu){
                selectedCompany = item.company;
                bankStatementIndex = index;
            } 
            else{
                selectedCompany = this.tempCompanies.find(item => item.name === this.company);
                bankStatementIndex = this.bankStatements.findIndex(item => item.id === this.selectedBankStatement.id);
            }

            const params = {
                matching_status: !fromMenu ? this.matchStatus : 'confirmed',
                company_id: selectedCompany ? selectedCompany.id : null
            }

            if(!params.company_id) delete params.company_id;

            const url = !fromMenu ? `/admin/bank-statement-items/${this.selectedBankStatement.id}` 
                                  : `/admin/bank-statement-items/${item.id}`

            ApiService.patch(url, { ...params })
                .then(response => {
                    if(response.status === 200){
                        this.show.editBankStatement = false;
                        this.company = {};

                        const data = response.data;

                        this.bankStatements[bankStatementIndex].match_status = data.match_status;
                        this.bankStatements[bankStatementIndex].company = data.company;

                        this.$snackbar(this.$t('bank_statements.changed_success'), 'success');
                    }
            })
            .catch((error)=> {
                for(let errorProp in error.response.data.errors)
                    this.$snackbar(this.$t(`bank_statements.errors.${errorProp}`), 'error')
            });

        },
        /**
         * Filter Unfiscalized bills
         */
        filterBankStatements: _.debounce(function() {
            this.getBankStatements();
        }, 500),


    }
};
</script>

<style lang="scss" scoped>
.bank-statements {
    padding: 20px;
    background: white;
    border-radius: 4px;
    border: 1px solid #d3d5dc;
}
</style>
