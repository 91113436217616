<template>
    <transition
        :name="transition"
        :mode="mode"
    >
        <slot />
    </transition>
</template>

<script>
export default {
    name: 'RouterTransition',

    props: {
        name: {
            type: String,
            default: null
        }
    },

    created() {
        this.$router.beforeEach((to, from, next) => {
            this.transition = this.name || to.meta.transition;
            next();
        });
    },

    data() {
        return {
            transition: 'fade',
            mode: 'out-in'
        };
    }
};
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
    transition-duration: 0.25s;
    transition-property: opacity;
    transition-timing-function: ease;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
}

.slide-left-enter-active,
.slide-left-leave-active {
    transition-duration: 0.25s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
}

.slide-left-enter,
.slide-left-leave-to {
    opacity: 0;
    transform: translate(2em, 0);
}

.slide-right-enter-active,
.slide-right-leave-active {
    transition-duration: 0.25s;
    transition-property: height, opacity, transform;
    transition-timing-function: cubic-bezier(0.55, 0, 0.1, 1);
    overflow: hidden;
}

.slide-right-enter,
.slide-right-leave-to {
    opacity: 0;
    transform: translate(-2em, 0);
}
</style>
