<template>
    <v-form
        v-if="form"
        ref="form"
        class="venue-form"
    >   
        <v-text-field
            v-model="form.name"
            :error-messages="form.$errors.name"
            label="Name"
            required
        />
        <v-text-field
            v-model="form.tax_id_number"
            :error-messages="form.$errors.PIB"
            label="PIB"
            required
        />

        <div class="d-flex justify-center mb-3">
            <v-btn
                color="primary"
                outlined
                @click="save"
            >
                Save
            </v-btn>
        </div>

    </v-form>
</template>

<script>
import ApiService from "@/services/ApiService";
import * as rules from "vuelidate/lib/validators";

export default {
    props: {
        company: {
            type: Object,
            default: () => null,
        },
    },

    created() {
        this.form = this.createForm(this.company);
    },

    data() {
        return {
            form: null,
        };
    },

    validations: {
        form: {
            name: { required: rules.required },
            tax_id_number: { required: rules.required },
        },
    },

    methods: {
        createForm(company = null) {
            return new CodForm(this, "form", {
                id: company ? company.id : null,
                name: company ? company.name : null,
                tax_id_number: company ? company.tax_id_number : null,
            });
        },

        /**
         * Save Venue
         */
        save() {
            if (!this.form.validate()) return;

            ApiService.patch(`/admin/companies/${this.form.id}`, this.form).then(
                (response) => {
                    this.$emit("save", response.data);
                    this.$snackbar("company updated", "success");
                }
            ).catch(
                error => {
                  if (error.response.status === 403) {
                    this.$snackbar('You are not authorized to perform this action.', 'error');
                  }
                }
            );;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
