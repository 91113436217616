<template>
    <v-form
        v-if="form"
        ref="form"
        class="venue-form"
    >


        <v-text-field
            v-model="form.hash"
            :error-messages="form.$errors.hash"
            label="hash"
            required
            :disabled="!canEdit"
        />

        <v-text-field
            v-model="form.name"
            :error-messages="form.$errors.name"
            :label="$t('general.name')"
            required
            :disabled="!canEdit"
        />

        <v-text-field
            v-model="form.address"
            :error-messages="form.$errors.address"
            :label="$t('general.adress')"
            required
            :disabled="!canEdit"
        />

        <v-text-field
            v-model="form.tax_id_number"
            :label="$t('general.tax_id_number')"
            required
            :disabled="!canEdit"
        />
        <v-checkbox
            v-model="form.taxes_enabled"
            :label="$t('general.tax_payer')"
        />
        <v-select 
            :items="['me', 'en', 'sr']"
            v-model="form.language"
            :label="$t('general.language')"
            :disabled="!canEdit"
        ></v-select>
        <v-checkbox
            v-model="form.user_deposit_enabled"
            :label="$t('general.user_deposit')"
            :disabled="!canEdit"
        />
        <v-divider/>
        <h3>{{$t('general.non_cash')}}</h3>
        <v-row>
            <v-col cols="6">
                <v-checkbox
                    v-model="form.noncash_only"
                    :label="$t('general.non_cash_only')"
                    :disabled="!canEdit"
                />
            </v-col>
        </v-row>
        <v-divider/>
        <h3>{{$t('general.other_settings')}}</h3>
        <v-row>
            <v-col cols="6">
                <v-checkbox
                    v-model="form.cash_change_enabled"
                    :label="$t('general.cash_change')"
                    :disabled="!canEdit"
                />
                <v-checkbox
                    v-model="form.update_tax_rates_enabled"
                    :label="$t('general.update_tax_rates')"
                    :disabled="!canEdit"
                />
            </v-col>
            <v-col cols="6">
                <v-checkbox
                    v-model="form.reverse_calculation"
                    :label="$t('general.reverse_calculation')"
                    :disabled="!canEdit"
                />
            </v-col>
        </v-row>

        <v-text-field
            v-model="form.barcode_pattern"
            :label="$t('general.barcode_pattern')"
            required
            :disabled="!canEdit"
        />
        <div class="d-flex justify-center mb-3">
            <v-btn
                color="primary"
                outlined
                @click="save"
            >
                {{$t('general.save')}}
            </v-btn>
        </div>

    </v-form>
</template>

<script>
import ApiService from '@/services/ApiService'
import * as rules from 'vuelidate/lib/validators'

export default {
    props: {
        tenant: {
            type: Object,
            default: () => null
        }
    },

    created() {
        this.form = this.createForm(this.tenant)
    },

    data() {
        return {
            form: null
        };
    },

    validations: {
        form: {
            hash: { required: rules.required },
            address: { required: rules.required },
            name: { required: rules.required },
            tax_id_number: { required: rules.required },
            barcode_pattern: { required: rules.required },
        }
    },

    watch: {
        tenant(val) {
            this.form = this.createForm(val)
        }
    },

    computed: {
        isFiscal() {
            return locale === 'me'
        },
        canEdit() {
            return this.$store.state.user.role === 'admin' || this.$store.state.user.role === 'main_reseller'
        }
    },

    methods: {
        createForm(tenant = null) {
            return new CodForm(this, 'form', {
                id: tenant ? tenant.venue.id : null,
                taxes_enabled: tenant ? tenant.venue.taxes_enabled : null,
                hash: tenant ? tenant.venue.hash : null,
                address: tenant ? tenant.venue.address : null,
                name: tenant ? tenant.venue.name : null,
                tax_id_number: tenant ? tenant.venue.tax_id_number : null,
                cash_change_enabled: tenant ? tenant.venue.settings.cash_change_enabled : null,
                user_deposit_enabled: tenant ? tenant.venue.settings.user_deposit_enabled : null,
                barcode_pattern: tenant ? tenant.venue.settings.barcode_pattern : null,
                update_tax_rates_enabled: tenant ? tenant.venue.settings.update_tax_rates_enabled : null,
                noncash_only: tenant ? tenant.venue.settings.noncash_only : null,
                language: tenant ? tenant.venue.language : null,
                reverse_calculation: tenant ? tenant.venue.settings.reverse_calculation : null,
            })
        },

        /**
         * Save Venue
         */
        save() {
            if (!this.form.validate()) return

            ApiService.patch(`/admin/venues/${this.form.id}`, this.form).then(response => {
                this.$emit('save', response.data.tenant)
                this.$snackbar('Venue updated', 'success');
            });
        }
    }
};
</script>

<style lang="scss" scoped>
.venue-form {
}
</style>
