<template>

    <v-form
        v-if="form"
        ref="form"
        class="modules-form"
    >
        <v-text-field
            v-model="form.name"
            :error-messages="form.$errors.name"
            :label="$t('general.name')"
            required
        />

        <v-select
            v-model="form.status"
            :items="['active', 'disabled']"
            :error-messages="form.$errors.status"
            :label="$t('general.status')"
            required
        >
        </v-select>

        <v-text-field
            v-model="form.description"
            :error-messages="form.$errors.description"
            :label="$t('general.description')"
        />

        <div class="d-flex justify-center mb-3">
            <v-btn
                color="primary"
                outlined
                @click="save"
            >
            <span v-if="action === 'patch'">
                {{$t('general.edit')}}
            </span>

                <span v-if="action === 'post'">
                {{$t('general.create')}}
            </span>
            </v-btn>
        </div>

    </v-form>
</template>

<script>
import ApiService from "@/services/ApiService";
import * as rules from "vuelidate/lib/validators";

export default {
    props: {
        module: {
            type: Object,
            default: () => null,
        },
        action: {
            type: String,
            default: () => 'patch',
        }
    },
    data() {
        return {
            form: this.createForm(this.module),
        };
    },
    //Follow prop object update for filling form
    watch: {
        module: {
            deep: true,
            immediate: true,
            handler() {
                this.form = this.createForm(this.module);
            },
        },
    },
    validations: {
        form: {
            name: { required: rules.required },
            status: { required: rules.required },
        },
    },
    methods: {
        createForm(module = null) {
            return new CodForm(this, "form", {
                id: module ? module.id : null,
                name: module ? module.name.replaceAll('_',' ') : null,
                status: module ? module.status : null,
                description: module ? module.description : null,

            });
        },
        /**
         * Save Module
         */
        save() {

            if (!this.form.validate()) return;

            const url = this.action === 'patch' ? `/admin/modules/${this.form.id}/update` : '/admin/modules';


            ApiService[this.action](url, this.form).then(
                (response) => {
                    this.$emit("save", response.data);
                    const actionMessage = this.action === 'post' ? 'created' : 'updated'
                    this.$snackbar(this.$t(`modules.${actionMessage}`), "success");
                }
            );

        },

    },
};
</script>

<style lang="scss" scoped>
</style>
