import VueRouter from 'vue-router';
import Store from '@/store';

import Login from '@/views/auth/Login';

import AdminLayout from '@/views/admin/AdminLayout';
import Tenants from '@/views/admin/Tenants/Tenants';
import EfiTransactions from '@/views/admin/EfiTransactions';
import Statistics from '@/views/admin/Stats/Statistics';
import UnfiscalizedBills from '@/views/admin/UnfiscalizedBills';
import AllCompanies from '@/views/admin/Company/AllCompanies';
import CompanyDetails from '@/views/admin/Company/CompanyDetails';
import LpfrTable from '@/views/admin/Company/LpfrTable';
import DebuggingLpfr from '@/views/admin/DebuggingLpfr';
import TenantDetails from '@/views/admin/Tenants/TenantDetails';
import BankStatements from '@/views/admin/Bank/BankStatements';
import AllResellers from '@/views/admin/Resellers/AllResellers';
import ResellerDetails from '@/views/admin/Resellers/ResellerDetails';

//Notifications
import AllNotifications from '@/views/admin/Notifications/AllNotifications';
import AllUsers from "@/views/admin/Users/AllUsers.vue";

//Modules
import AllModules from '@/views/admin/Modules/AllModules';

//Contracts
import AllContracts from '@/views/admin/Contracts/AllContracts';

//Integrations
import AllIntegrations from '@/views/admin/Integrations/AllIntegrations';
import BillingStatistics from "../views/admin/Stats/BillingStatistics.vue";


const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
        },
        {
            path: '/admin',
            name: 'admin',
            redirect: '/admin/customers',
            component: AdminLayout,

            children: [
                {
                    path: 'customers',
                    name: 'customers',
                    component: Tenants,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'efi-transactions',
                    name: 'efi-transactions',
                    component: EfiTransactions,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'statistics',
                    name: 'statistics',
                    component: Statistics,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'billingstatistics',
                    name: 'billingstatistics',
                    component: BillingStatistics,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'unfiscalizedbills',
                    name: 'unfiscalizedbills',
                    component: UnfiscalizedBills,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'allcompanies',
                    name: 'allcompanies',
                    component: AllCompanies,
                    meta: { transition: 'fade' },

                },
                {
                    path: 'company/:id',
                    name: 'companyDetails',
                    component: CompanyDetails,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'lpfrTable',
                    name: 'lpfrTable',
                    component: LpfrTable,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'debug',
                    name: 'debug',
                    component: DebuggingLpfr,
                    meta: { transition: 'fade' }
                },

                {
                    path: 'tenant/:id',
                    name: 'tenantDetails',
                    component: TenantDetails,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'bankStatements',
                    name: 'bankStatements',
                    component: BankStatements,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'resellers',
                    name: 'resellers',
                    component: AllResellers,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'reseller/:id',
                    name: 'resellerDetails',
                    component: ResellerDetails,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'notifications',
                    name: 'notifications',
                    component: AllNotifications,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'users',
                    name: 'users',
                    component: AllUsers,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'contracts',
                    name: 'contracts',
                    component: AllContracts,
                },
                {
                    path: 'modules',
                    name: 'modules',
                    component: AllModules,
                    meta: { transition: 'fade' }
                },
                {
                    path: 'integrations',
                    name: 'integrations',
                    component: AllIntegrations,
                    meta: { transition: 'fade' }
                },
            ]
        },

    ]
});

router.beforeEach(async (to, from, next) => {
    let user = Store.getters.user;

    if(!user) {
        await Store.dispatch('initializeApp')
        user = Store.getters.user;
    }

    const guestRoutes = ['login'];

    if (user && guestRoutes.includes(to.name)) {
        router.push({ name: 'customers' });
        return next(false);
    }

    if (!user && !guestRoutes.includes(to.name)) {
        router.push({ name: 'login' });
        return next(false);
    }

    return next();
});

export default router;
