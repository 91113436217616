<template>
    <v-form
        v-if="form"
        ref="form"
        class="venue-form"
    >
      <v-text-field
          v-model="form.old_password"
          :error-messages="form.$errors.old_password"
          :label="$t('general.old_password')"
          :append-icon="showOldPassword ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"
          :type="showOldPassword ? 'text' : 'password'"
          @click:append="showOldPassword = !showOldPassword"
      />
      <v-text-field
          v-model="form.password"
          :error-messages="form.$errors.password"
          :label="$t('general.password')"
          :append-icon="showPassword ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
      />

      <v-text-field
          v-model="form.password_confirmation"
          :error-messages="form.$errors.passwordConfirmation"
          :label="$t('general.confirm_password')"
          :append-icon="showPasswordConfirmation ? 'mdi mdi-eye-outline' : 'mdi mdi-eye-off-outline'"
          :type="showPasswordConfirmation ? 'text' : 'password'"
          @click:append="showPasswordConfirmation = !showPasswordConfirmation"
      />

        <div class="d-flex justify-center mb-3">
            <v-btn
                color="primary"
                outlined
                @click="save"
            >
                Save
            </v-btn>
        </div>

    </v-form>
</template>

<script>
import ApiService from "@/services/ApiService";
import * as rules from "vuelidate/lib/validators";
import {minLength, sameAs} from "vuelidate/lib/validators";

export default {
    props: {
        userData: {
            type: Object,
            default: () => null,
        },
        dialog: {
          type: Boolean,
          default: false,
        },
    },

    created() {
        this.form = this.createForm(this.userData);
    },

    data() {
        return {
            form: null,
            showPassword: false,
            showPasswordConfirmation: false,
            showOldPassword: false,
        };
    },

    validations: {
        form: {
          password: { minLength: minLength(8) },
          passwordConfirmation: {
            sameAs: sameAs(function () { return this.password })
          },
            old_password: { required: rules.required },
        },
    },
    watch:{
        dialog() {
          this.form = this.createForm(this.userData);
        }
    },
    methods: {
        createForm(user = null) {
            return new CodForm(this, "form", {
                id: user ? user.id : null,

            });
        },

        /**
         * Save Venue
         */
        save() {
            if (!this.form.validate()) return;

            ApiService.patch(`/admin/users/${this.form.id}/change-password`, this.form).then(
                (response) => {
                    this.$emit("save", response.data);
                    this.$snackbar("Password updated", "success");
                    this.form = this.createForm(this.userData);
                }
            ).catch(
                error => {
                  if (error.response.status === 403) {
                    this.$snackbar('You are not authorized to perform this action.', 'error');
                  }
                }
            );
        },
      resetForm() {
        this.form.$reset();
      },
    },
};
</script>

<style lang="scss" scoped>
</style>
