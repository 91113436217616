<template>
    <div class="stat">
        <div v-if="isLoading" class="overlay">
            <!-- Add a loading indicator component or message here -->
            <div class="loading-text loader">

            </div>
        </div>

        <v-row>
            <v-col cols="12" md="5">
                <v-select
                    v-model="selectedResellers"
                    :items="resellers"
                    item-text="name"
                    item-value="id"
                    :label="$t('statistics.resellers')"
                    multiple
                    append-icon="mdi mdi-chevron-down"
                    prepend-icon="mdi mdi-account-tie"
                ></v-select>
            </v-col>
            <v-col cols="12" md="5">

                <v-text-field
                    v-model="dateShow"
                    :label="$t('statistics.select_date_range')"
                    readonly
                    @click="openDialog"
                    prepend-icon="mdi mdi-calendar"
                ></v-text-field>


            </v-col>
            <v-col cols="12" md="1">
                <v-btn
                    class="mt-3"
                    outlined
                    @click="getBillingData"
                >
                    <v-icon>mdi mdi-filter-outline</v-icon>
                    {{$t('statistics.filter')}}
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="6" md="5">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <div v-on="on">
                            <v-stat
                                :value="totalInvoiced"
                                icon="mdi mdi-cash-multiple"
                                backgroundColor="black"
                                :label="$t('statistics.total_invoiced')"
                                :aria="ariaInvoices"
                                :allegra="allegraInvoices"
                                :currency="currency"
                            />
                        </div>
                    </template>
                    <span>{{$t('statistics.total_invoiced_details')}}</span>
                </v-tooltip>
            </v-col> <v-col cols="12" sm="6" md="3">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <div v-on="on">
                        <v-stat
                            :value="totalPaid"
                            :label="$t('statistics.total_paid')"
                            icon="mdi mdi-currency-usd"
                            backgroundColor="green"
                            :currency="currency"
                        />
                    </div>
                </template>
                <span>{{$t('statistics.total_paid_details')}}</span>
            </v-tooltip>
        </v-col> <v-col cols="12" sm="6" md="3">
            <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                    <div v-on="on">
                        <v-stat
                            :value="totalDebt"
                            :label="$t('statistics.total_debt')"
                            icon="mdi mdi-currency-usd-off"
                            backgroundColor="red"
                            :currency="currency"
                        />
                    </div>
                </template>
                <span>{{$t('statistics.total_debt_details')}}</span>
            </v-tooltip>
        </v-col>

        </v-row>
        <v-row>
            <v-col cols="12">
                <Chart
                    :key="chart_billing.key"
                    :currency="currency"
                    chart-name="Billing Info"
                    :props-labels="chart_billing.labels"
                    :orders-aria="chart_billing.aria"
                    :orders-allegra="chart_billing.allegra"
                    :orders-total="chart_billing.total"
                    :calculationLabels="calculationLabels"
                    :header-text="$t('statistics.billing')"/>
            </v-col>
        </v-row>

        <v-dialog v-model="show.dialog" width="350">
            <div>
                <v-card>
                    <div class="dialog-header">
                        {{$t('companies.payments.dialog')}}
                    </div>
                    <div style="padding:4%">
                        <div style="text-align:center">
                            <v-date-picker
                                v-model="date"
                                label="Date"
                                :max="currentDate"
                                range
                                @input="validateDateRange"
                            >
                            </v-date-picker>
                        </div>

                    </div>
                </v-card>
            </div>
        </v-dialog>

    </div>
</template>
<script>

import VStat from "@/views/admin/Stats/VStat.vue";
import Chart from "@/views/shared/Chart.vue";

export default {
    components: {
        VStat,
        Chart
    },

    data() {
        return {
            calculationLabels: [
                this.$t('statistics.total_invoiced'),
                this.$t('statistics.total_paid'),
                this.$t('statistics.total_debt')
            ],
            dateShow: null,
            show: {
                dialog: false,
            },
            ariaInvoices: [],
            allegraInvoices: [],
            resellers: [],
            selectedResellers: [],
            totalInvoiced: 0,
            totalPaid: 0,
            totalDebt: 0,
            chart_billing: {
                aria: [],
                allegra: [],
                total: [],
                labels: [],
                key_label: 'chart_billing',
                key: ''
            },
            date: null,
            minDate:new Date().toISOString().substr(0, 10),
            isLoading: true,
            currentDate: null,
            currency: '',
        }
    },
    mounted() {
        this.currentDate = new Date().toISOString().substr(0, 10);
        this.date = [new Date(2021, 0, 1).toISOString().split('T')[0], this.currentDate];

        this.getCountry();
        this.getResellers();

        this.getBillingData();
    },

    watch: {
        'date': {
            handler(newValue) {

                this.dateShow = this.getFormattedDateRange(newValue);
            },
            immediate: true, // Optional: Trigger the handler immediately on component creation
        },
    },
    methods: {
        getCountry(){
            ApiService.get('/admin/global-config')
                .then(response => {
                    this.currency = response.data.country === 'me' ? '€' : 'RSD';
                })
                .catch(error => {
                    console.log(error);
                });
        },

        validateDateRange() {
            const [startDate, endDate] = this.date;

            if (startDate && endDate && startDate >= endDate) {
                // If the first picked date is greater than or equal to the second one,
                // reset the date range or perform any desired action
                this.date = [endDate, startDate];
            }
        },
        openDialog(){
            this.show.dialog = true;
        },
        getFormattedDateRange(dates) {
            if (Array.isArray(dates) && dates.length === 2) {
                const startDate = this.formatDate(dates[0]);
                const endDate = this.formatDate(dates[1]);
                return `${startDate} - ${endDate}`;
            }
            return '';
        },
        formatDate(date) {
            const parsedDate = new Date(date);
            const year = parsedDate.getFullYear();
            const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed, so add 1
            const day = String(parsedDate.getDate()).padStart(2, '0');

            // Use string interpolation to format the date as Y-m-d
            return `${day}/${month}/${year}`;
        },
        getBillingData() {
            this.isLoading = true; // Show the loader
            ApiService.get('/admin/billing/stats', {
                params: {
                    date: this.date,
                    resellerIds: this.selectedResellers
                }
            })
                .then(response => {

                    this.chart_billing.labels = Object.keys(response.data.invoiced);
                    this.chart_billing.aria = Object.values(response.data.invoiced);
                    this.chart_billing.allegra = Object.values(response.data.paid);
                    this.chart_billing.total = this.chart_billing.aria.map((value, index) => value - this.chart_billing.allegra[index])
                    this.totalInvoiced = this.chart_billing.aria.reduce((a, b) => a + b, 0);
                    this.totalPaid = this.chart_billing.allegra.reduce((a, b) => a + b, 0);
                    this.totalDebt = this.chart_billing.total.reduce((a, b) => a + b, 0);
                    this.ariaInvoices = response.data.aria_invoices;
                    this.allegraInvoices = response.data.allegra_invoices;

                    this.chart_billing.key= this.chart_billing.key_label+'_'+this.increment;

                    this.totalPaid = this.totalPaid.toLocaleString();
                    this.totalInvoiced = this.totalInvoiced.toLocaleString();
                    this.totalDebt = this.totalDebt.toLocaleString();

                    this.isLoading = false;

                })
                .catch(error => {
                    this.$snackbar(this.$t('efi_transactions.error_string'), 'error');
                    this.isLoading = false;
                })
        },
        getResellers() {

            ApiService.get('/admin/resellers')
                .then(response => {
                    this.resellers = response.data.resellers;
                })
                .catch(error => {
                    console.log(error);
                })
        }
    }
}


</script>
<style scoped>
.stat {
    padding: 20px;
    background: white;
    border-radius: 4px;
    border: 1px solid #d3d5dc;
}
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}
.loading-text {
    position: fixed;
    top: 10%;
    left: 50%;
}
.loader {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: 4px solid #fff;
    border-top-color: #000; /* Customize the color of the loader */
    animation: loader-rotation 1s linear infinite; /* Customize the animation duration and easing */
}
@keyframes loader-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
