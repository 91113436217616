<template>
    <v-app class="app-container">

        <!-- Top Progress Bar -->
        <v-progress-linear
            v-if="loading"
            class="loading"
            color="#1565c0"
            indeterminate
            absolute
        />

        <!-- Snackbar -->
        <cod-snackbar />

        <!-- Confirm Dialog -->
        <cod-confirm />
        
        <!-- App Content -->
        <router-view />
        
    </v-app>
</template>
<script>
import UserService from '@/services/UserService';
import * as mutationTypes from '@/store/mutationTypes';

import i18n from '@/i18n'
import RouterTransition from '@/router/RouterTransition';
import CodSnackbar from '@/components/CodSnackbar';
import CodConfirm from '@/components/CodConfirm';

export default {
    components: {
        RouterTransition,
        CodSnackbar,
        CodConfirm,
    },

    async created() {

        EventBus.$on('show-loading', loading => {
            this.loading = loading;
        });
    },
    mounted(){
        // Setting language based on env app_locale variable
        ApiService.get("locale").then(response => {
            i18n.locale = response.data.toLowerCase();
        })
    },
    data: () => {
        return {
            loading: false
        };
    }
};
</script>

<style lang="scss">

// Utilities
@import '@/styles/utility/spaces';
@import '@/styles/utility/vuetify-custom';

.app-container {
    height: 100%;

    .loading {
        z-index: 999999;
    }
}
</style>
