import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true
        },
        themes: {
            dark: false,
        }
    },
    icons: {
        iconfont: 'mdi',
    }
});

// Extending Vuetify
Object.defineProperty(Vue.prototype, '$snackbar', {
    value: function(text, type = null, options = {}) {
        EventBus.$emit('show-snackbar', text, type, options);
    }
});

Object.defineProperty(Vue.prototype, '$confirm', {
    value: function(message, options = {}, callback = () => {}) {
        EventBus.$emit('show-confirm', message, options, callback);
    }
});
