<template>
    <div>
    <v-progress-linear
        :active="saveLoading"
        :indeterminate="saveLoading"
        color="blue"
        top
    ></v-progress-linear>
    <div class="form">
        <v-form
            ref="form"
            v-model="valid"
            lazy-validation
        >
        <v-autocomplete
            v-model="form.tenants"
            :items="tenants"
            :loading="isLoading"
            :search-input.sync="search"
            :error-messages="autoCompleteValidation && !allTenants && !ownTenants  ? 'Primalac je obavezan': null"
            :disabled="allTenants || saveLoading || ownTenants"
            hide-no-data
            hide-selected
            item-text="email"
            item-value="id"
            :label="this.$t('notifications.searchReceivers')"
            :placeholder="this.$t('notifications.placeholderAutocomplete')"
            prepend-icon="mdi-account"
            return-object
            chips
            deletable-chips
            multiple
            required
        ></v-autocomplete>
            <v-checkbox
                :disabled="saveLoading || ownTenants"
                v-model="allTenants"
                :label="this.$t('notifications.allPeople')"
            ></v-checkbox>
            <v-checkbox
                :disabled="saveLoading || allTenants"
                v-model="ownTenants"
                :label="this.$t('notifications.ownUsers')"
            ></v-checkbox>
            <v-textarea
                outlined
                name="input-7-3"
                :rules="messageRules"
                :label="this.$t('general.notification')"
                v-model="form.message"
                :disabled="saveLoading"
                required
            ></v-textarea>

            <div class="d-flex justify-center mb-3">
                <v-btn
                    color="primary"
                    outlined
                    @click="warningModal"
                    :disabled="saveLoading"
                >
                    {{ this.$t('general.create') }}
                </v-btn>
            </div>
        </v-form>
        <v-dialog
            v-model="show.warn"
            width="500"
        >
            <div class="dialog">
                <div class="dialog-header">
                    {{$t('general.warning')}}
                </div>
                <div
                    class="form">
                <div v-if="sendData.tenants.length > 0">

                <div class="dialogText">
                    <div>
                        {{$t('notifications.warningMessage') + " " + sendData.tenants.length + " " + $t('notifications.users') }}
                    </div>

                </div>
                <div class="d-flex justify-space-between">
                    <v-btn
                        class="mt-7 align-center"
                        width="200px"
                        color="red"
                        outlined
                        @click="show.warn = false"
                    >
                        {{ this.$t('notifications.cancel') }}
                    </v-btn>
                    <v-btn
                        class="mt-7 align-center"
                        width="200px"
                        color="primary"
                        outlined
                        @click="save"
                    >
                        {{ this.$t('general.send') }}
                    </v-btn>
                </div>

                </div>
                    <div v-else>
                    <h3>{{$t('notifications.noUsers') }}</h3>
                </div>

                </div>


            </div>
        </v-dialog>

    </div>
    </div>
</template>
<script>
import ApiService from "@/services/ApiService";
import * as rules from "vuelidate/lib/validators";

export default {
    data() {
        return {
            allTenantsArray: [],
            valid:true,
            search: null,
            isLoading:false,
            saveLoading: false,
            autoCompleteValidation:false,
            form: {
                tenants: [],
                message: ""
            },
            sendData: {
                tenants: [],
                message: ""
            },
            show:{
                warn:false
            },
            allTenants: false,
            ownTenants: false,
            ownTenantsQuery: '',
            tenants: [],
            messageRules: [
                v => !!v || 'Poruka je obavezna',
            ],
        };
    },
    watch:{
        // Watch if all tenants checkbox is selected
        allTenants(){
            if(this.allTenants){
                this.form.tenants = []
                this.tenants = []
                this.sendData.tenants = []
                this.allTenantsArray = []
            }
        },
        // Watch if own tenants checkbox is selected
        ownTenants(){
            if(this.ownTenants){
                this.tenants = []
                this.form.tenants = []
                this.sendData.tenants = []
                this.allTenantsArray = []
                this.ownTenantsQuery = true
            }else{
                this.ownTenantsQuery = ''
            }
        },
        // Watch for search changes and run only after or eq 3 characters
        search (val) {
            if(val && val.length >= 3) {
                this.isLoading = true
                // Load tenats
                this.getUserTenants();
            }else{
                this.tenants = this.form.tenants;
            }
        }
    },
    methods:{
        /**
         * Get tenants related to loged in user with email search
         */
        async getUserTenants() {
            await ApiService.get(`/admin/tenants/get-user-tenants?email_filter=${this.search}&resellerTenants=${this.ownTenantsQuery}`).then(
                response => {
                    if(this.allTenants || this.ownTenants) {
                        this.allTenantsArray = response.data.tenants
                    }else{
                        response.data.tenants.push(...this.form.tenants)
                        this.count = response.data.tenants.length
                        this.tenants = response.data.tenants
                        //Check if all tenants is selected
                        this.isLoading = false
                    }
                }
            );
        },

        async warningModal(){

            //Validate fields
            if(this.form.tenants.length == 0 && !this.allTenants && !this.ownTenants) {
                this.autoCompleteValidation = true;
                return;
            }

            if(!this.$refs.form.validate()) return;


            //If all tenants are selected get list of all related and then store
            if(this.allTenants || this.ownTenants){
                this.form.tenants = [];
                this.search = '';
                await this.getUserTenants();
                this.sendData.tenants = this.allTenantsArray
                this.sendData.message = this.form.message
                this.form.tenants = [];
            }else{
                this.sendData = { ...this.form }
            }

            //Converting array of objects to array of ids
            const arrayOfIds = this.sendData.tenants.map(obj => obj.id);
            this.sendData.tenants = arrayOfIds

            this.show.warn = true
        },

        /**
         * Save Notification
         */
        async save() {

            this.saveLoading = true;
            this.show.warn = false;

            ApiService.post(`/admin/notifications/store`, this.sendData ).then(response => {
                this.$emit('save', response.data)

                this.tenants = [];
                this.form.tenants = [];
                this.sendData.tenants = []
                this.sendData.message = ''
                this.form.message = ''
                this.allTenants = false
                this.ownTenants = false
                this.saveLoading = false
                this.$snackbar('Notification created', 'success');

            });
        },
        /**
         * Remove tenant from chips
         */
        remove (item) {
            const index = this.form.tenants.indexOf(item.email)
            if (index >= 0) this.form.tenants.splice(index, 1)
        }
    },
}
</script>

<style>
.form{
    background: white;
    padding: 20px;
}
</style>
