<template>
  <div class="container">
    <h2> {{headerText}} </h2>
    <canvas :id="chartName">
    </canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'

export default {
  props: {
    chartName: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      default: ''
    },
    calculationLabels: {
      type: Array,
      default: () => ['Aria', 'Allegra', 'Total']
    },
    propsLabels: {
      type: Array,
      default: () => []
    },
    ordersAria: {
      type: Array,
      default: () => []
    },
    ordersAllegra: {
      type: Array,
      default: () => []
    },
    ordersTotal: {
      type: Array,
      default: () => []
    },
    headerText: {
      type: String,
      default: 'Chart'
    }
  },
  data() {
    return {
      aritaChartData: null,
    }
  },
  watch:{
    propsLabels(){
      this.generateChart()
    },
  },
  mounted() {
    this.generateChart()
  },
    methods: {
      generateChart(){

        this.aritaChartData = {
          type: "line",
          data: {
            labels: [],
            datasets: [
              {
                label: "",
                data: [],
                backgroundColor: "rgba(54,73,93,.5)",
                borderColor: "#36495d",
                borderWidth: 3
              },
              {
                label: "",
                data: [],
                backgroundColor: "rgba(71, 183,132,.5)",
                borderColor: "#99ccff",
                borderWidth: 3
              },
              {
                label: "",
                data: [],
                backgroundColor: "rgba(0, 128, 255)",
                borderColor: "#47b784",
                borderWidth: 3,
                hidden: true
              }
            ]
          },
          options: {
            responsive: true,
            display:true,
            lineTension: 1,
            scales: {
              yAxes: [
                {
                  ticks: {
                    beginAtZero: true,
                    padding: 25,
                    userCallback: (value, index, values) => { // Use arrow function here
                      return value.toLocaleString() + ' ' + this.currency;
                    },
                  },
                },
              ],
            },
            tooltips: {
              callbacks: {
                label: (tooltipItem, data) => { // Use arrow function here
                  var label = data.datasets[tooltipItem.datasetIndex].label || '';
                  if (label) {
                    label += ': ';
                  }
                  label += tooltipItem.yLabel.toLocaleString() + ' ' + this.currency;
                  return label;
                },
              },
            }
            },
          };

        this.aritaChartData.data.labels = this.propsLabels;
        this.aritaChartData.data.datasets[0].data = this.ordersAria;
        this.aritaChartData.data.datasets[0].label = this.calculationLabels[0];
        this.aritaChartData.data.datasets[1].data = this.ordersAllegra;
        this.aritaChartData.data.datasets[1].label = this.calculationLabels[1];
        this.aritaChartData.data.datasets[2].data = this.ordersTotal;
        this.aritaChartData.data.datasets[2].label = this.calculationLabels[2];

        const ctx = document.getElementById(this.chartName).getContext("2d")
        new Chart(ctx, this.aritaChartData);

      }
    }
}
</script>

<style scoped>
  .container{
      margin-top:2%
  }
</style>